import React, { useState, useEffect, useCallback } from 'react';
import 'rsuite/dist/styles/rsuite-default.css';
import * as API from '../../../ApiUrl';
import { useSelector } from 'react-redux';
import { FilterDropdown } from '../components';
import { Box, Stack, Autocomplete, TextField, MenuItem } from '@mui/material';
import { downloadFormats, reportTimePeriods } from '../common';
import { ButtonPrimary } from '../../../components';
import { DateRangePicker } from 'rsuite';
import { merchantApi } from '../../../redux/api/apiConfig';
import usePageTitle from '../../../hooks/usePageTitle';
import { toast } from 'react-toastify';

const { afterToday } = DateRangePicker;

function WalletMovement() {
  const [mtoAccounts, setMtoAccounts] = useState([]);
  const [walletMovement, setWalletMovement] = useState({
    coin: '',
    mtoAccount: null,
    format: 'pdf',
    calenderValue: [new Date(reportTimePeriods[0].from), new Date(reportTimePeriods[0].to)],
    period: reportTimePeriods[0],
  });
  usePageTitle('Wallet Movement', 'View the balance in wallet and history of movement of funds in that wallet');
  const { walletsReducer } = useSelector((state) => state);

  const { wallets = [] } = walletsReducer;

  const getAccounts = useCallback(async () => {
    try {
      const response = await merchantApi.get(API.MTO_ACCOUNTS);
      setMtoAccounts(response.data.results);
    } catch (error) {
      toast.error(error?.data?.data?.message || 'Something went wrong');
      throw new Error('Something went wrong');
    }
  }, []);

  useEffect(() => {
    getAccounts();
  }, [getAccounts]);

  return (
    <>
      <Box sx={{ padding: '0 24px 24px' }}>
        <Stack direction={'row'} justifyContent={'space-between'} width={'100%'} mb={2}>
          <Stack gap={2} direction={'row'}>
            <Stack sx={{ maxWidth: '25%', flex: 1.5 }}>
              <Autocomplete
                // disablePortal
                id='mto_account'
                value={walletMovement.mtoAccount}
                onChange={(e, newValue) => {
                  setWalletMovement((prevState) => ({
                    ...prevState,
                    mtoAccount: newValue ?? null,
                  }));
                }}
                options={mtoAccounts}
                getOptionLabel={(option) => option.name}
                sx={{ flexGrow: 1, width: '100%' }}
                renderInput={(params) => {
                  return <TextField placeholder='Select MTO Account' {...params} size='small' />;
                }}
              />
            </Stack>
            {wallets ? (
              <Stack sx={{ maxWidth: '25%', flex: 1 }}>
                <FilterDropdown
                  name='from'
                  type='text'
                  label='From'
                  options={wallets}
                  onChange={(e) => {
                    const coin = e.target.value;

                    setWalletMovement((prevState) => ({
                      ...prevState,
                      coin,
                    }));
                  }}
                  value={walletMovement?.coin}
                  size={'small'}
                  render={(wallet, i) => (
                    <MenuItem key={wallet.coin.id} value={wallet.coin.id}>
                      {wallet.coin.symbol}
                    </MenuItem>
                  )}
                />
              </Stack>
            ) : (
              ''
            )}
            <Stack sx={{ maxWidth: '25%', flex: 1 }}>
              <FilterDropdown
                name='from'
                type='text'
                label='From'
                options={reportTimePeriods}
                onChange={(e) => {
                  const name = e.target.value;
                  const period = reportTimePeriods.find((report) => report.name === name);
                  console.log(period);
                  setWalletMovement((prevState) => ({
                    ...prevState,
                    calenderValue: [new Date(period.from), new Date(period.to)],
                    period,
                  }));
                }}
                value={walletMovement?.period?.name}
                size={'small'}
                render={(period, i) => (
                  <MenuItem key={period.name} value={period.name}>
                    {period.name}
                  </MenuItem>
                )}
              />
            </Stack>
            <Stack sx={{ maxWidth: '25%', flex: 1 }}>
              <DateRangePicker
                cleanable
                placeholder='Filter Date'
                className='w-100 dateInput'
                size='lg'
                style={{ height: '40px' }}
                // onOk={handleDateFilter}
                value={walletMovement.calenderValue}
                placement='auto'
                showOneCalendar={true}
                disabledDate={afterToday()}
                onClean={(dara) => {
                  setWalletMovement((prevState) => ({
                    ...prevState,
                    calenderValue: [new Date(reportTimePeriods[0].from), new Date(reportTimePeriods[0].to)],
                    period: {
                      from: reportTimePeriods[0].from,
                      to: reportTimePeriods[0].to,
                      name: reportTimePeriods[0].name,
                    },
                  }));
                }}
              />
            </Stack>
            <Stack sx={{ maxWidth: '25%', flex: 0.5 }}>
              <FilterDropdown
                name='from'
                type='text'
                label='From'
                options={downloadFormats}
                onChange={(e) => {
                  const value = e.target.value;
                  setWalletMovement((prevState) => ({
                    ...prevState,
                    format: value,
                  }));
                }}
                value={walletMovement?.format}
                size={'small'}
                render={(format, i) => (
                  <MenuItem key={format.name} value={format.value}>
                    {format.name}
                  </MenuItem>
                )}
              />
            </Stack>
          </Stack>
          <ButtonPrimary>Download</ButtonPrimary>
        </Stack>
      </Box>
    </>
  );
}

export default WalletMovement;
