import React, { useState, useEffect, useCallback } from 'react';
import { toast } from 'react-toastify';
import 'rsuite/dist/styles/rsuite-default.css';
import * as API from '../../../ApiUrl';
import { useSelector } from 'react-redux';
import { FilterDropdown } from '../components';
import { Box, Stack, Autocomplete, TextField, MenuItem } from '@mui/material';
import { downloadFormats, reportTimePeriods } from '../common';
import { formatCurrency } from '../../../utils/common';
import { ButtonPrimary } from '../../../components';
import { DateRangePicker } from 'rsuite';
import { merchantApi } from '../../../redux/api/apiConfig';
import usePageTitle from '../../../hooks/usePageTitle';

const { afterToday } = DateRangePicker;

function MtoLedgerDetails() {
  const [mtoLedgerDetails, setMtoLedgerDetails] = useState({
    mto: null,
    format: 'pdf',
    calenderValue: [new Date(reportTimePeriods[0].from), new Date(reportTimePeriods[0].to)],
    period: reportTimePeriods[0],
  });

  const [mtoOptions, setMtoOptions] = useState([]);

  usePageTitle('MTO Ledger Details', 'View transactions with a particular MTO, amount owed and redemption history');
  const { profileReducer } = useSelector((state) => state);
  const {
    user: { mto },
  } = profileReducer;

  const getMtoOptions = useCallback(async () => {
    try {
      const response = await merchantApi.get(`${API.MTO}`);
      console.log(response.data);
      setMtoOptions(response.data);
    } catch (error) {
      toast.error(error?.data?.data?.message || 'Something went wrong');
      // toast.error('Could not fetch MTO list.');
    }
  }, []);

  useEffect(() => {
    getMtoOptions();
    setMtoLedgerDetails((prevState) => ({ ...prevState, mto }));
  }, [getMtoOptions, mto]);

  return (
    <>
      <Box sx={{ padding: '0 24px 24px' }}>
        <Stack direction={'row'} justifyContent={'space-between'} width={'100%'} mb={2}>
          <Stack gap={2} direction={'row'}>
            <Stack sx={{ maxWidth: '35%', flex: 2.5 }}>
              <Autocomplete
                id='mto'
                value={mtoLedgerDetails.mto}
                onChange={(e, newValue) => {
                  setMtoLedgerDetails((prevState) => ({
                    ...prevState,
                    mto: newValue ?? null,
                  }));
                }}
                options={mtoOptions}
                getOptionLabel={(option) => `${option?.name} (${option?.country?.name})`}
                sx={{ flexGrow: 1, width: '100%' }}
                renderInput={(params) => {
                  return <TextField placeholder='Select MTO' {...params} size='small' />;
                }}
              />
            </Stack>
            <Stack sx={{ maxWidth: '25%', flex: 1 }}>
              <FilterDropdown
                name='from'
                type='text'
                label='From'
                options={reportTimePeriods}
                onChange={(e) => {
                  const name = e.target.value;
                  const period = reportTimePeriods.find((report) => report.name === name);
                  console.log(period);
                  setMtoLedgerDetails((prevState) => ({
                    ...prevState,
                    calenderValue: [new Date(period.from), new Date(period.to)],
                    period,
                  }));
                }}
                value={mtoLedgerDetails?.period?.name}
                size={'small'}
                render={(period, i) => (
                  <MenuItem key={period.name} value={period.name}>
                    {period.name}
                  </MenuItem>
                )}
              />
            </Stack>
            <Stack sx={{ maxWidth: '55%', flex: 1 }}>
              <DateRangePicker
                cleanable
                placeholder='Filter Date'
                className='w-100 dateInput'
                size='lg'
                style={{ height: '40px' }}
                // onOk={handleDateFilter}
                value={mtoLedgerDetails.calenderValue}
                placement='auto'
                showOneCalendar={true}
                disabledDate={afterToday()}
                onClean={(dara) => {
                  setMtoLedgerDetails((prevState) => ({
                    ...prevState,
                    calenderValue: [new Date(reportTimePeriods[0].from), new Date(reportTimePeriods[0].to)],
                    period: {
                      from: reportTimePeriods[0].from,
                      to: reportTimePeriods[0].to,
                      name: reportTimePeriods[0].name,
                    },
                  }));
                }}
              />
            </Stack>
            <Stack sx={{ maxWidth: '25%', flex: 1 }}>
              <FilterDropdown
                name='from'
                type='text'
                label='From'
                options={downloadFormats}
                onChange={(e) => {
                  const value = e.target.value;
                  // const format = downloadFormats.find(
                  //   (format) => format.name === name
                  // );
                  console.log(formatCurrency);
                  setMtoLedgerDetails((prevState) => ({
                    ...prevState,
                    format: value,
                  }));
                }}
                value={mtoLedgerDetails?.format}
                size={'small'}
                render={(format, i) => (
                  <MenuItem key={format.name} value={format.value}>
                    {format.name}
                  </MenuItem>
                )}
              />
            </Stack>
          </Stack>
          <ButtonPrimary>Download</ButtonPrimary>
        </Stack>
      </Box>
    </>
  );
}

export default MtoLedgerDetails;
