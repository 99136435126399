import React, { useCallback, useEffect, useState } from "react";
import { merchantApi } from "../../../../../redux/api/apiConfig";
import {
  CLIENT_WALLET_TRANSACTIONS,
  SENDER_REMITTANCE,
} from "../../../../../ApiUrl";
import Transactiontable from "./TransactionDetailsTable";
import { Box } from "@mui/material";
import { toast } from "react-toastify";
import HeaderCard from "../../../../../components/headerCard/headerCard";

const TransactionDetails = ({ client }) => {
  const [loading, setLoading] = useState(true);
  const [clientTransactions, setClientTransactions] = useState([]);
  const [paginateTable, setpaginateTable] = useState({
    currentPage: 0,
    pageSize: 7,
    // search: '',
    // type: '',
    // coin: '',
    // store: '',
    // status: '',
    // fromDate: new Date(reportTimePeriods[1].from),
    // toDate: new Date(reportTimePeriods[1].to),
  });

  const getClientTransactions = useCallback(async () => {
    try {
      setLoading(true);
      const response = await merchantApi.get(SENDER_REMITTANCE, {
        params: { sender: client?.sender?.id },
      });
      console.log(response);
      setClientTransactions(response.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      toast.error(error?.data?.data?.message || "Something went wrong");
      setLoading(false);
    }
  }, [client.id]);

  useEffect(() => {
    getClientTransactions();
  }, [getClientTransactions]);

  console.log("remittance", clientTransactions);
  return (
    <Box>
      <HeaderCard
        title="Total Amount"
        total={clientTransactions?.total?.send_amount || 0}
        sx={{marginBottom: '30px'}}
      />

      <Transactiontable
        translist={clientTransactions}
        paginateTable={paginateTable}
        setpaginateTable={setpaginateTable}
        fetchTranslistLoading={loading}
        viewDetails={(x) => console.log(x)}
      />
    </Box>
  );
};

export default TransactionDetails;
