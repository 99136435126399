import React from 'react';
import { Stack, Tooltip, Typography } from '@mui/material';
import Iconify from './Iconify';
import { toast } from 'react-toastify';

const PreviewItem = ({ title, content, copy = false, tooltip = true, sx }) => {
  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(content);
      toast.success('Copied to clipboard');
    } catch (err) {
      toast.error('Unable to copy to clipboard');
    }
  };
  return (
    <Tooltip title={tooltip ? content : ''}>
      <Stack sx={{ ...sx, cursor: 'default' }} direction={'column'} spacing={0} alignItems={'flex-start'}>
        <Stack>
          <Typography sx={{ color: '#4a4a4a' }} variant='caption'>
            {title}
          </Typography>
        </Stack>
        <Stack
          sx={{
            maxWidth: '100%',
            flexDirection: 'row',
            alignItems: 'center',
            marginRight: 2,
          }}
        >
          <Typography
            variant='subtitle2'
            sx={{
              wordBreak: 'break-word',
              // overflow: 'hidden',
              // textOverflow: 'ellipsis',
            }}
          >
            {content ? content : '--'}
          </Typography>
          {content && copy ? (
            <Iconify
              icon={'eva:copy-outline'}
              sx={{
                color: '#757575',
                margin: '0 !important',
                cursor: 'pointer',
              }}
              onClick={copyToClipboard}
            />
          ) : null}
        </Stack>
      </Stack>
    </Tooltip>
  );
};

export default PreviewItem;
