//export const BASE_URL = "https://dev2api.spendthebits.com";
// export const BASE_URL = 'https://devapi.spendthebits.com';
// export const BASE_URL = 'https://testapi.spendthebits.com';
export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const VERSION = `${BASE_URL}/v1`;
const BASE_URL_MERCHANT = `${VERSION}/merchant`;

export const countryFlagApi = 'https://flagcdn.com/';

export const KYC_USERS = BASE_URL + '/kyc/user_kyc/';
export const KYC_LEVELS = BASE_URL + '/kyc/kyc_level/';

// MTO
export const MTO = BASE_URL + '/mto/';

// Deposits
export const DEPOSITS = MTO + 'deposits/';
export const DEPOSIT_STATUS = DEPOSITS + 'depositstatusoption/';

export const DASHBOARD = MTO + 'dashboard/';
export const COINWISE = DASHBOARD + 'coin_wise/';
export const PENDING_AND_CURRENT_REDEMPTION = DASHBOARD + 'pending_and_current_redemption/';
export const CUSTOMER_HISTORY = DASHBOARD + 'customer_history/';
export const COMMISSIONS_HISTORY = DASHBOARD + 'commission_history/';
export const CUSTOMER_BASE = DASHBOARD + 'customer_base/';
export const AVERAGE_REMITTANCE = DASHBOARD + 'average_remittance/';
export const LOGIN_API = MTO + 'user/login/';
export const SIGNUP_API = MTO + 'user/register/';
export const RESEND_PASSWORD_MAIL = MTO + 'user/resend_password_mail/';
export const RESET_PASSWORD = MTO + 'user/change_password/';
export const MTO_ACCOUNTS = MTO + 'account/';
export const MTO_BALANCES = MTO_ACCOUNTS + 'balance/';
export const MTO_ACCOUNT_TYPES = MTO_ACCOUNTS + 'types/';
export const MTO_TRANSFER = MTO + 'transaction/';
export const VERIFY_PASSWORD = MTO + 'user/verify_password/';
export const UPDATE_PASSWORD = MTO + 'user/update_password/';
export const NEW_PASSWORD = MTO + 'user/new_password/';
export const TRANSFER_TRANSACTION = MTO + 'transaction/list/';
export const FEE = MTO + 'fee/';
export const OFFER = MTO + 'offer/';
export const BULK_OFFER = OFFER + 'bulk_update/';
export const LIQUIDITY = MTO + 'liquidity/';
export const USER_ROLES = MTO + 'roles/';
export const SYSTEM_USER = MTO + 'user/';
export const MTO_PROFILE = MTO + 'user/profile/';
export const USER_DOCUMENT_TYPE = MTO + 'user/document_type/';
export const MTO_PERMISSIONS = BASE_URL + '/permission/';
// REPORTS
export const REPORT = BASE_URL + '/report';
export const REMITTANCE_REPORT = REPORT + '/remittance/';
export const REMITTANCE_HIGHLIGHT = REMITTANCE_REPORT + 'highlight/';
export const REDEMPTION_REPORT = REPORT + '/redemption/';
export const PENDING_REDEMPTION_REPORT = REPORT + '/pending_redemption/';
export const DEPOSIT_REPORT = REPORT + '/deposit/';
export const TRANSACTION_REPORT = REPORT + '/transaction/';
export const TRANSACTION = BASE_URL + '/transaction/';
export const GET_WALLETS = BASE_URL + '/wallet/';
export const GET_SIGNER_ADDRESS = MTO + 'signer/';
export const GET_COINS = BASE_URL + '/coin/';
export const TRUST_LINE = BASE_URL + '/wallet/trust_line/';
export const FINANCIAL_OPTIONS = BASE_URL + '/financial/instruments/';
export const COUNTRY_LIST = BASE_URL + '/country/';
export const PROVINCE_LIST = BASE_URL + '/province/';
export const CITY_LIST = BASE_URL + '/city/';

export const SENDER = BASE_URL + '/sender';
export const SENDER_IND = SENDER + '/individual/';
export const ADD_SENDER_IND = SENDER + '/individual/';
export const ADD_SENDER_BUS = SENDER + '/business/';
export const SENDER_DOCUMENT = SENDER + '/document/';
export const SENDER_UPLOAD_DOCUMENT = SENDER + '/business/kyb/update/add-kyb-document/';
export const CLIENT_WALLET_TRANSACTIONS = BASE_URL + '/customer/wallet/transactions/';
export const SENDER_REMITTANCE = '/remittance/outgoing/';

export const RECEIVER_IND = BASE_URL + '/receiver/individual/';
export const ADD_RECEIVER_IND = BASE_URL + '/receiver/individual/';
export const ADD_RECEIVER_BUS = BASE_URL + '/receiver/business/';

export const ADD_FINANCIAL_DATA = BASE_URL + '/financial/';

// Remittance
export const REMITTANCE = BASE_URL + '/remittance/';
export const REMITTANCE_UPLOAD = REMITTANCE + 'upload/';
export const INCOMING_REMITTANCE_STATUS = REMITTANCE + 'incoming_statuses/';
export const REMITTANCE_OUTGOING = REMITTANCE + 'outgoing/';
export const REMITTANCE_INCOMING = REMITTANCE + 'incoming/';
export const REMITTANCE_MTO = REMITTANCE + 'mto/';
export const REMITTANCE_TYPES = REMITTANCE + 'types/';
export const REMITTANCE_PURPOSE = REMITTANCE + 'puposes/';
export const DEPOSIT_TYPES = REMITTANCE + 'deposit_types/';
export const REMITTANCE_RATE = REMITTANCE + 'currency_rate/';
export const REMITTANCE_STATUSES = REMITTANCE + 'statuses/';
export const CALCULATE_FEE = REMITTANCE + 'calculate_fee/';

// Redeem
export const REDEEM = BASE_URL + '/redeem/';
export const REDEEM_LIST = REDEEM + 'remittance/';
export const REDEEM_RECEIVED_LIST = REDEEM + 'received/';
export const REDEEM_STATUSES = REDEEM + 'statuses/';
export const PENDING_REDEEM = REDEEM + 'pending/';

// mto
export const CURRENCY_TYPES = BASE_URL + '/currency/';

// export const SIGNUP_API = BASE_URL_MERCHANT + '/user/register/';
export const ADD_MERCHANT_API = BASE_URL_MERCHANT + '/business/';
export const CONFIGURE_API = BASE_URL_MERCHANT + '/configure/';
export const TERMS_CONDITION = 'https://spendthebits.com/terms-conditions/';
export const PRIVACY_POLICY = 'https://spendthebits.com/privacy-policy/';
export const XRPL_TEST_ACCOUNT_CREATE_URL = 'https://faucet.altnet.rippletest.net/accounts';
// export const XRPL_TEST_ACCOUNT_CREATE_URL =
//   "https://s.altnet.rippletest.net:51234";

export const ROLES = BASE_URL_MERCHANT + '/role/';

export const COMPANY_ROLE = BASE_URL_MERCHANT + '/company_role/';

export const SEARCH_ADDRESS_BY_PAYSTRING = VERSION + '/paystring/address/';

//Revenue
export const GET_REVENUE = BASE_URL_MERCHANT + '/report/revenue';
export const GET_SALES = BASE_URL_MERCHANT + '/report/sales/';
export const GROUP_BY_RECEIPT = BASE_URL_MERCHANT + '/report/group_by_receipt/';
export const GET_CUSTOMER_REPORT = BASE_URL_MERCHANT + '/report/customers/';
export const GET_MEMBERS_REPORT = BASE_URL_MERCHANT + '/report/members/size_history/';
// https://devapi.spendthebits.com/v1/merchant/report/members/size_history/
// https://devapi.spendthebits.com/v1/merchant/report/customers/
// https://devapi.spendthebits.com/v1/merchant/report/group_by_receipt/
// https://devapi.spendthebits.com/v1/merchant/report/sales/
// https://devapi.spendthebits.com/v1/merchant/report/revenue/?group_by=day

// Occupation
export const OCCUPATION_LIST = VERSION + '/occupations/';

// Resend password mail

//User
export const MERCHANT_PROFILE = BASE_URL_MERCHANT + '/user/';
export const USER_PROFILE = BASE_URL_MERCHANT + '/account/user_profile/';
export const BUSINESS_TYPE_API = BASE_URL_MERCHANT + '/business_type/';
export const CATEGORY_API = BASE_URL_MERCHANT + '/category/';
export const UPLOAD_DOCUMENT = BASE_URL_MERCHANT + '​/account/business/';
export const GET_DOCUMET_TYPE = BASE_URL_MERCHANT + '/kyb/doc_type/';
export const GET_PROFILE = BASE_URL_MERCHANT + '/account/user_profile/';
export const CATEGORY_LIST = `${BASE_URL_MERCHANT}/category/`;
export const RESENT_ACCOUNT_ACTIVATION_EMAIL = MTO + 'user/resend_password_mail/';
export const GET_DOC_TYPES = '/kyc/document_type/';
export const GET_DOC_TYPES_KYB = '/sender/business/kyb/doc_types/';

//kyb
export const KYB_START = BASE_URL_MERCHANT + '/kyb/';
export const KYB_STATUS = BASE_URL_MERCHANT + '/kyb/';
export const UPLOAD_KYB_DOC = BASE_URL_MERCHANT + '/kyb/';

//TRANSACTIONS
export const FUND_TRANSACTION = BASE_URL_MERCHANT + '/transaction/fund_transactions/';
export const GET_TRANSACTION = VERSION + '/transaction/';
export const EXPORT_EXCEL = GET_TRANSACTION + 'export/';

// https://devapi.spendthebits.com/v1/transaction/export/

// https://devapi.spendthebits.com/v1/transaction/?type=receipt
export const RESEND_OTP = BASE_URL_MERCHANT + '/transaction/resend_otp/';
export const VERIFY_OTP = VERSION + '/transaction/verify_otp/';

// Transaction
export const TRANSACTION_LIST = VERSION + '/transaction';
export const COIN = BASE_URL_MERCHANT + '/coin/';
export const TRANS_STORE = VERSION + '/transaction/stores/';
export const TRANS_STATUS = VERSION + '/transaction/status/';
export const TRANSACTION_TYPE = VERSION + '/transaction/type/';
export const TRANSACTION_APPROVE_REJECT = VERSION + '/transaction/approve_or_decline/';
export const TRANSACTION_VERIFY_WITHDRAW_ADDRESS = VERSION + '/transaction/verify_withdraw_address/';
export const RESEND_REFUND_OTP = TRANSACTION_LIST + 'resend_otp/';
// https://devapi.spendthebits.com/v1/transaction/resend_otp/

export const TRANSACTION_GRAPH_DATA = VERSION + '/transaction/graph_data/';
// https://devapi.spendthebits.com/v1/transaction/graph_data/

// Change Password
export const FORGOT_PASSWORD = MTO + 'user/reset_password/';
export const RESET_NEW_PASSWORD = BASE_URL_MERCHANT + '/user/reset_password/';

//// STORE
export const STORE_LIST = BASE_URL_MERCHANT + '/store/';
export const COUNTER = BASE_URL_MERCHANT + '/store/counter/';

// support
export const SUPPORT = BASE_URL + '/support/';
export const SUPPORT_CATEGORY = SUPPORT + 'category/';
export const SUPPORT_STATUS = SUPPORT + 'status/';
export const TICKET = SUPPORT + 'ticket/';
export const TICKET_LIST = SUPPORT + 'ticket/';
export const TICKET_REPLY = SUPPORT + 'ticket/reply/';
export const GET_TICKET_REPLY = SUPPORT + 'ticket/reply/';
export const GET_SUPPORT = SUPPORT + 'ticket/';
export const TICKET_STATUS = SUPPORT + 'ticket/';
export const TICKET_PRIORITY = SUPPORT + 'priority/';

//Dashboard
export const CHART_DATA = BASE_URL_MERCHANT + '/report/revenue/';
export const REVENUE = BASE_URL_MERCHANT + '/report/revenue/';
export const CUSTOMERS_IN_PERIOD = BASE_URL_MERCHANT + '/report/customer_in_period/';
export const TEAM_MEMBERS_LIST = `${BASE_URL_MERCHANT}/report/members/`;
export const TOTAL_RECEIPTS_LIST = `${BASE_URL_MERCHANT}/report/total_receipt/`;

//Config
export const CONFIG = BASE_URL_MERCHANT + '/config/';
export const GLOBAL_CONFIG = VERSION + '/config/';
export const READINESS = BASE_URL_MERCHANT + '/readiness/';
export const TIMEZONES = BASE_URL + '/timezones/';

// Paystring
export const PAY_STRING = BASE_URL_MERCHANT + '/paystring/';
export const PAYSTRING_DOMAIN = VERSION + '/paystring/domain/';

//SecretKey
export const GET_PASS_PHRASE = VERSION + '/wallet/passphrase/';
// export const GET_WALLET = VERSION + '/wallet/';
export const GET_WALLET_ISSUER = VERSION + '/wallet/issuer_address/';
export const GET_MERCHENT_WALLET_LIST = BASE_URL_MERCHANT + '/wallet';

// Balance
export const GET_BALANCE = BASE_URL + '/wallet/balance/';
export const GET_BALANCE_GRAPH_DATA = GET_BALANCE + 'history/';

// https://devapi.spendthebits.com/v1/merchant/balance/history/

// Merchant
export const CHECK_SINGLE_WALLET = BASE_URL_MERCHANT + '/config/';

// Fund wallet
// https://devapi.spendthebits.com/v1/transaction/pay/
export const INITIAL_FUND = VERSION + '/transaction/pay/';
export const VERIFY_CAPTCHA = BASE_URL_MERCHANT + '/user/verify_captcha/';

//

// CHAT
export const SUPPORT_TICKET = BASE_URL + '/usersupport/agent/ticket/';
export const SUPPORT_TICKET_REPLY = BASE_URL + '/usersupport/agent/reply/';

export const NOTIFICATION_HISTORY = BASE_URL + '/notification/history/';
export const MEDIATOR = BASE_URL + '/mediator/';

export const FILE = BASE_URL + '/file/';
export const REFRESH_TOKEN_API = '/refresh/';
export const UPDATE_KYC = '/sender/shareholder/update_kyc/';
export const UPDATE_KYB = '/sender/business/kyb/update/';


