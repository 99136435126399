import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'rsuite';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Dialog, DialogContent, DialogActions, DialogTitle } from '@mui/material';
import _ from 'lodash';
import { toast } from 'react-toastify';

import { getWallets } from '../../redux/actions/wallet';
import { useDispatch } from 'react-redux';
import * as API from '../../ApiUrl';
import { get_wallet, trust_set } from '../../utils/xrpl_functions';
import copy from '../../assets/img/icons/copy.svg';
import { textFieldLabelProps } from '../../utils/common';
import axios from 'axios';
import { merchantApi } from '../../redux/api/apiConfig';

const WalletTrustLineSet = (props) => {
  const { open, handleClose, selectedCoin } = props;
  const [isLoading, setisLoading] = useState(false);
  const [passphraseKey, setPassphraseKey] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [numberOfAttempts, setNumberOfAttempts] = useState(0);
  const [time, setTime] = useState(0);
  const [initial, setInitial] = useState(true);

  const history = useHistory();
  const dispatch = useDispatch();

  const handleClickShowPassword = () => {
    setShowPassword((prevState) => !prevState);
  };
  let token = localStorage.getItem('accessToken');

  console.log(numberOfAttempts);
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };

  let timer = useRef();
  let times = useRef(0);
  useEffect(() => {
    if (!initial) {
      timer.current = setInterval(() => {
        setTime((time) => time + 1);
        times.current = times.current + 1;
      }, 1000);
    }

    return () => {
      clearInterval(timer.current);
    };
  }, [time, times, initial]);

  // console.log(times.current);

  const submitPassPhrase = async () => {
    setInitial(false);
    setTime(0);
    times.current = 0;
    setTimeout(() => {
      console.log(times.current);
    }, 5000);
    try {
      const a = await get_wallet(passphraseKey);
      setisLoading(true);
      console.log(passphraseKey);
      // Removed address verification to facilitate multisign
      // if (a.address === selectedCoin.address) {
      await setTrustLine();
      // } else {
      //   toast.error('Wrong Secret Key or Address');
      //   setNumberOfAttempts((prevValue) => prevValue + 1);
      //   setisLoading(false);
      // }
    } catch (err) {
      console.log(err);
      toast.error(' Trust-line could not be set, please double check your secret key');
      setNumberOfAttempts((prevValue) => prevValue + 1);
      clearInterval(timer.current);
      setTime(0);
      times.current = 0;
      setisLoading(false);
    }
  };

  const setTrustLine = async () => {
    console.log(times.current, 'setTrustLine');
    try {
      if (times.current >= 10) throw new Error('Timeout');
      const b = await trust_set(
        selectedCoin.address,
        selectedCoin.coin.issuer_address,
        passphraseKey,
        selectedCoin.coin.symbol,
        Number(selectedCoin.coin.trust_line_amount)
        // 10000
      );
      console.log(b);
      if (b) {
        const data = {
          blob: `${b}`,
          coin: selectedCoin.coin.id,
        };
        await sendMessageToServer(data);
      } else {
        throw new Error('Invalid Blob');
      }
    } catch (err) {
      setisLoading(false);
      toast.error(' Trust-line could not be set, please double check your secret key');
      clearInterval(timer.current);
      setTime(0);
      times.current = 0;
      setNumberOfAttempts((prevValue) => prevValue + 1);
    }
  };

  const sendMessageToServer = async (value) => {
    console.log(times.current, 'sendMessageToServer');
    try {
      if (times.current >= 10) throw new Error('Timeout');
      const response = await merchantApi.post(API.TRUST_LINE, value, config);
      const { data } = response;
      toast.success(data.message);
      setisLoading(false);
      dispatch(getWallets());
      handleClose();

      setPassphraseKey('');
      setNumberOfAttempts(0);
    } catch (error) {
      setisLoading(false);
      toast.error(' Trust-line could not be set, please double check your secret key');
      clearInterval(timer.current);
      setTime(0);
      times.current = 0;
      setNumberOfAttempts((prevValue) => prevValue + 1);
    }
  };

  const copyAddress = () => {
    try {
      navigator.clipboard.writeText(selectedCoin.address);
      toast.success('Address copied to clipboard');
    } catch (err) {
      toast.error('Could not copy passphrase to clipboard');
    }
  };

  const handleCloseModal = (event, reason) => {
    if (reason && reason === 'backdropClick') return;
    handleClose();
  };

  return (
    <Dialog
      open={open}
      disableEscapeKeyDown={true}
      onClose={handleCloseModal}
      fullWidth
      className='wallet-configure'
      BackdropProps={{ style: { backgroundColor: 'rgba(11, 5, 35, 0.8)' } }}
      PaperProps={{
        style: {
          boxShadow: 'none',
          borderRadius: '8px',
          width: '452px',
          minHeight: '315px',
          maxHeight: '80vh',
        },
      }}
    >
      <DialogTitle className='text-center'>
        <p className='dialog-title'>Enter your secret key to continue</p>
      </DialogTitle>
      <hr className='hr' />
      <DialogContent className='merchant-wallet'>
        <h6 className='text-center mb-3'>
          Address <br />
          <p className='copy-add'>
            <b className='light_color'>{selectedCoin.address}</b>
            <button onClick={copyAddress}>
              <img src={copy} alt='copy' />
            </button>
          </p>
        </h6>
        <div className='form-floating mb-2 mt-4 '>
          <FormControl sx={{ m: 1 }} variant='outlined' className='w-100 pb-0 mb-0'>
            <InputLabel style={textFieldLabelProps.style} htmlFor='passphrase'>
              Enter Passphrase
            </InputLabel>
            <OutlinedInput
              className='w-100'
              id='passphrase'
              type={showPassword ? 'text' : 'password'}
              label='Enter Passphrase'
              value={passphraseKey}
              onChange={(e) => {
                setPassphraseKey(e.target.value);
              }}
              autoComplete='off'
              endAdornment={
                <InputAdornment position='end'>
                  <IconButton aria-label='toggle passphrase visibility' onClick={handleClickShowPassword} edge='end'>
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
        </div>
        {numberOfAttempts >= 3 ? (
          <div className='alert-msg'>
            <p>
              {' '}
              You seem to have failed 3 times, if you think you are entering the correct secret key, you can raise a ticket by clicking{' '}
              <span
                className='link'
                onClick={() => {
                  history.push({
                    pathname: '/support/',
                    state: { openTicketModal: true },
                  });
                }}
              >
                {' '}
                here.{' '}
              </span>
              <p style={{ marginTop: '1rem', fontWeight: '400' }}>PLEASE DO NOT SHARE YOUR SECRET KEY EVEN WITH OUR SUPPORT STAFF.</p>
            </p>
          </div>
        ) : null}
      </DialogContent>
      <hr className='hr' />
      <DialogActions className='footer-content'>
        <Button className='btn white-btn action-button basis-30' onClick={handleClose}>
          Cancel
        </Button>
        <Button
          className={`btn action-button primary_color text-white basis-70 ${isLoading || !passphraseKey ? 'disabled' : null}`}
          disabled={isLoading || !passphraseKey}
          loading={isLoading}
          onClick={submitPassPhrase}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default WalletTrustLineSet;
// export default WalletTrustLineSet;
