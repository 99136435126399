import React, { useState } from "react";
import { PreviewContainer, PreviewItem } from "../../../../../components";
import { capitalize } from "lodash";
import { format } from "date-fns";
import { Box, Stack } from "@mui/material";
import { formatDate } from "../../../../../utils/common";
import KycKybStatusUpdate from "./kyckybStatusUpateForm";
const BasicDetails = ({ client, type }) => {
  const [adminShareholder, setAdminShareholder] = useState(
    client?.shareholders?.find((item) => item?.email === client?.user?.email)
  );
  let details;
  if (type === "individual") {
    const {
      first_name,
      last_name,
      xrpl_tag,
      city,
      province,
      country,
      status,
      primary_contact_number,
      email,
      kyc_status,
      dob,
    } = client;
    details = (
      <>
        <PreviewItem title={"Name"} content={`${first_name} ${last_name}`} />
        <PreviewItem title={"Xrpl Tag"} content={xrpl_tag} />
        <PreviewItem title={"City"} content={city?.name} />
        <PreviewItem title={"Province"} content={province?.name} />
        <PreviewItem title={"Country"} content={country?.name} />
        <PreviewItem title={"Phone"} content={primary_contact_number} />
        <PreviewItem title={"Email"} content={email} />
        <PreviewItem
          title={"Date of Birth"}
          content={dob ? format(new Date(dob), "MM-dd-yyyy") : null}
        />
        <PreviewItem title={"Status"} content={capitalize(status)} />
        <PreviewItem title={"KYC Status"} content={capitalize(kyc_status)} />
      </>
    );
  } else {
    const {
      business_name,
      xrpl_tag = "",
      city,
      province,
      country,
      status,
      dob,
      kyc_status,
      primary_contact_name,
      primary_designation,
      primary_contact_no,
      secondary_contact_no,
    } = client;
    details = (
      <>
        <PreviewItem title={"Business Name"} content={business_name} />
        <PreviewItem
          title={"Primary Contact Name"}
          content={primary_contact_name}
        />
        <PreviewItem
          title={"Primary Contact Designation"}
          content={primary_designation}
        />
        <PreviewItem title={"Xrpl Tag"} content={xrpl_tag} />
        <PreviewItem title={"City"} content={city?.name} />
        <PreviewItem title={"Province"} content={province?.name} />
        <PreviewItem title={"Country"} content={country?.name} />
        <PreviewItem
          title={"Date of Birth"}
          content={dob ? format(new Date(dob), "MM-dd-yyyy") : null}
        />
        <PreviewItem
          title={"Primary Contact No"}
          content={primary_contact_no}
        />
        <PreviewItem
          title={"Secondary Contact No"}
          content={secondary_contact_no}
        />
        <PreviewItem title={"Status"} content={capitalize(status)} />
        <PreviewItem title={"KYC Status"} content={capitalize(kyc_status)} />
      </>
    );
  }

  const businessInfo = (
    <>
      <PreviewItem title={"Business Name"} content={client?.business_name} />
      <PreviewItem title={"Full Name"} content={client?.primary_contact_name} />
      <PreviewItem title={"Country"} content={client?.country?.name} />
      <PreviewItem title={"Province"} content={client?.province?.name} />
      <PreviewItem title={"City"} content={client?.city?.name} />
      <PreviewItem title={"Designation"} content={client?.designation} />
      <PreviewItem
        title={"Business Category"}
        content={client?.category?.parent_category?.name}
      />
      <PreviewItem
        title={"Business Sub Category"}
        content={client?.category?.name}
      />
      <PreviewItem title={"Occupation Type"} content={client?.occupation} />
      <PreviewItem title={"Registration Number"} content={client?.company_id} />
      <PreviewItem title={"Postal Code"} content={client?.postal_code} />
      <PreviewItem title={"Timezone"} content={client?.time_zone} />
      <PreviewItem
        title={"Date of Incorporation"}
        content={client?.incorporation_date}
      />
      <PreviewItem title={"Website"} content={client?.company_url} />
      <PreviewItem
        title={"KYB Status"}
        content={client?.kyb_status?.status?.replace(/_/g, " ")}
      />
      <PreviewItem title={"Address"} content={client?.address} />
    </>
  );

  let commAddress = "";

  if (!client?.is_same_with_mailing_address) {
    commAddress = (
      <>
        <PreviewItem
          title={"Province"}
          content={client?.mailing_province?.name}
        />
        <PreviewItem title={"City"} content={client?.mailing_city?.name} />
        <PreviewItem
          title={"Postal Code"}
          content={client?.mailing_postal_code}
        />
        <PreviewItem title={"Address"} content={client?.mailing_address} />
      </>
    );
  }

  let personalInfo = "";

  if (
    client?.shareholders?.find((item) => item?.email === client?.user?.email)
  ) {
    // const item = client?.shareholders?.find(
    //   (item) => item?.email === client?.user?.email
    // );
    personalInfo = (
      <>
        <PreviewItem
          title={"First Name"}
          content={adminShareholder?.first_name}
        />
        <PreviewItem
          title={"Last Name"}
          content={adminShareholder?.last_name}
        />
        <PreviewItem title={"Date of Birth"} content={adminShareholder?.dob} />
        <PreviewItem
          title={"Primary Phone Number"}
          content={adminShareholder?.primary_phone_number}
        />
        <PreviewItem
          title={"Secondary Phone Number"}
          content={adminShareholder?.secondary_phone_number}
        />
        <PreviewItem
          title={"Country"}
          content={adminShareholder?.country?.name}
        />
        <PreviewItem
          title={"Province"}
          content={adminShareholder?.province?.name}
        />
        <PreviewItem title={"City"} content={adminShareholder?.city?.name} />
        <PreviewItem
          title={"Postal Code"}
          content={adminShareholder?.postal_code}
        />
        <PreviewItem
          title={"Residence Address"}
          content={adminShareholder?.address}
        />
        <PreviewItem
          title={"Passport Number"}
          content={adminShareholder?.passport_number}
        />
        <PreviewItem
          title={"Ownership"}
          content={`${adminShareholder?.ownership}%`}
        />
      </>
    );
  }

  return (
    <Stack sx={{ marginTop: "1.5rem" }} spacing={3} direction={"column"}>
      {type === "individual" ? (
        <PreviewContainer>{details}</PreviewContainer>
      ) : (
        <>
          <PreviewContainer title="Business Info">
            {businessInfo}
          </PreviewContainer>
          {!client?.is_same_with_mailing_address && (
            <PreviewContainer title="Business Communication Address">
              {commAddress}
            </PreviewContainer>
          )}

          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
            }}
          >
            <KycKybStatusUpdate
              data={client?.kyb_status?.status}
              id={client?.kyb_status?.id}
              isKyb
            />
          </Box>
          <PreviewContainer title="Personal Info">
            {personalInfo}
          </PreviewContainer>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
            }}
          >
            <KycKybStatusUpdate
              data={adminShareholder?.kyc?.status}
              id={adminShareholder?.kyc?.id}
            />
          </Box>
          {client?.shareholders?.map((item, i) => {
            if (item?.email !== client?.user?.email) {
              return (
                <>
                  <PreviewContainer key={i} title="Shareholder Data">
                    <PreviewItem
                      title={"First Name"}
                      content={item?.first_name}
                    />
                    <PreviewItem
                      title={"Last Name"}
                      content={item?.last_name}
                    />
                    <PreviewItem title={"Date of Birth"} content={item?.dob} />
                    <PreviewItem title={"Email"} content={item?.email} />
                    <PreviewItem
                      title={"Primary Phone Number"}
                      content={item?.primary_phone_number}
                    />
                    <PreviewItem
                      title={"Ownership"}
                      content={`${item?.ownership}%`}
                    />
                  </PreviewContainer>
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "1fr 1fr",
                    }}
                  >
                    <KycKybStatusUpdate
                      data={item?.kyc?.status}
                      id={item?.kyc?.id}
                    />
                  </Box>
                </>
              );
            }
          })}
        </>
      )}
    </Stack>
  );
};

export default BasicDetails;
