import React, { useState, useEffect } from "react";
import { Stack, Link } from "@mui/material";
import Image from "../image/Image";
import { ButtonSecondary, Iconify } from "..";
import { merchantApi } from "../../redux/api/apiConfig";
import { SENDER_DOCUMENT } from "../../ApiUrl";
import { getThumbnail } from "../../utils/common";
import { toast } from "react-toastify";
import pdfIcon from "../../assets/img/pdfIcon.png";

const DocumentCard = ({
  isSelected = false,
  documents,
  onDocumentSelect = () => {},
  onDocumentDelete = () => {},
  canDelete = false,
  docName,
}) => {
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [isPdfDoc, setIsPdfDoc] = useState(false);

  const deleteClickHandler = async () => {
    setDeleteLoading(true);
    try {
      const response = await merchantApi.delete(
        `${SENDER_DOCUMENT}${documents.id}/`
      );
      onDocumentDelete(documents.id);
      setDeleteLoading(false);
    } catch (err) {
      toast.error(err?.data?.data?.message || "Something went wrong");
      console.log(err);
    }
  };

  // Check to display doc icon if mime type is not any image

  useEffect(() => {
    if (documents) {
      async function checkIsPdfHandler() {
        try {
          const response = await fetch(documents?.document);
          if (!response.ok) {
            throw new Error(
              `Failed to fetch the file: ${response.status} ${response.statusText}`
            );
          }

          const blob = await response.blob();
          console.log("file BLOB", blob);
          const url = URL.createObjectURL(blob);

          // Determine the file extension based on the MIME type of the Blob
          const mimeType = blob.type;
          if (
            mimeType !== "image/png" &&
            mimeType !== "image/jpg" &&
            mimeType !== "image/jpeg" &&
            mimeType !== "image/svg"
          ) {
            setIsPdfDoc(true);
          }
        } catch (error) {
          console.error("Error downloading file:", error.message);
        }
      }
      checkIsPdfHandler();
    }
  }, [documents]);

  // Download Doc or img without previewing it

  useEffect(() => {
    const downloadFile = async () => {
      console.log('download', documents);
      try {
        const response = await fetch(documents?.document);
        if (!response.ok) {
          throw new Error(
            `Failed to fetch the file: ${response.status} ${response.statusText}`
          );
        }

        const blob = await response.blob();
        console.log("file BLOB", blob);
        const url = URL.createObjectURL(blob);

        // Determine the file extension based on the MIME type of the Blob
        const mimeType = blob.type;
        let fileExtension = "";

        if (mimeType) {
          switch (mimeType) {
            case "application/pdf":
              fileExtension = ".pdf";
              break;
            case "application/msword":
            case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
              fileExtension = ".docx";
              break;
            case "image/jpeg":
              fileExtension = ".jpeg";
              break;
            case "image/png":
              fileExtension = ".png";
              break;
            case "image/jpg":
              fileExtension = ".jpg";
              break;
            // Add more MIME type to file extension mappings as needed
            default:
              fileExtension = ""; // Unknown MIME type, leave blank
          }
        }

        // Construct the file name dynamically using a base name and the file extension
        const baseFileName = "downloaded_file";
        const fileName = `${baseFileName}${fileExtension}`;

        const link = document.createElement("a");
        link.href = url;
        link.download = fileName;

        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      } catch (error) {
        console.error("Error downloading file:", error.message);
      }
    };

    // Assign the download function to a click event
    const button = document.getElementById(`download-button-${documents?.id}`);
    button.addEventListener("click", downloadFile);

    // Cleanup the event listener when the component unmounts
    return () => {
      button.removeEventListener("click", downloadFile);
    };
  }, [documents]);

  return (
    <Stack spacing={1}>
      <Image
        key={documents.id}
        alt="file preview"
        src={isPdfDoc ? pdfIcon : documents.document}
        title={
          docName ||
          `${documents?.name ?? ""} (${documents?.document_type?.name ?? ""})`
        }
        isSelected={isSelected}
        onClick={() => onDocumentSelect(documents.id)}
        sx={{
          borderRadius: 1,
          width: "200px",
          height: "150px",
          cursor: "pointer",
          border: "1px solid #F0F0F0",
        }}
      />
      {/* <Stack>
        <ButtonPrimary onClick={() => onDocumentSelect(documents.id)}>
          Select
        </ButtonPrimary>
      </Stack> */}
      <Stack direction={"row"} spacing={0.5}>
        <ButtonSecondary style={{ width: "100%" }} id={`download-button-${documents?.id}`}>
          Download
        </ButtonSecondary>
        {canDelete && (
          <ButtonSecondary
            onClick={deleteClickHandler}
            loading={deleteLoading}
            style={{ flexGrow: "1" }}
          >
            <Iconify icon={"eva:trash-2-outline"} />
          </ButtonSecondary>
        )}
      </Stack>
    </Stack>
  );
};

export default DocumentCard;
