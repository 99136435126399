import React, { useState, useEffect, useCallback } from 'react';
import { merchantApi } from '../../../redux/api/apiConfig';
import * as API from '../../../ApiUrl';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import 'rsuite/dist/styles/rsuite-default.css';
import { FilterDropdown, HighlightSkeleton } from '../components';
import { Box, Stack, MenuItem, Autocomplete, TextField, Card, Divider, OutlinedInput, InputAdornment } from '@mui/material';
import { reportTimePeriods } from '../common';
import { ButtonPrimary, ButtonSecondary, ButtonResetFilter, Iconify, TextFieldComponent } from '../../../components';
import { format as Format } from 'date-fns';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DepositReportTable from './depositReportTable';
import { Highlight } from '../components';
import { StyledDatePicker } from '../../../components/datePicker';
import usePageTitle from '../../../hooks/usePageTitle';
import { formatDate } from '../../../utils/common';
import { CLEAR_DEPOSIT_REPORT, UPDATE_DEPOSIT_REPORT } from '../../../redux/actionTypes';

// New Filter arrangement
// Filter style
// max-width: 72%;
// overflow: auto;
// padding: 0 10px;

function DepositReport() {
  const [loading, setLoading] = useState(false);
  const [highlightLoading, setHighlightLoading] = useState(false);

  const [translist, setTranslist] = useState({ count: 0 });
  const [highlights, setHighlights] = useState([]);

  const {
    profileReducer: { user: { mto: { currency: { code } = {} } = {} } = {} },
    configReducer: { depositTypes } = {},
  } = useSelector((state) => state);
  const filters = useSelector((state) => state.filters.reports.depositReport);

  console.log(reportTimePeriods, null);

  usePageTitle('Deposit Report', 'Deposit Analytics');
  const dispatch = useDispatch();

  const getReport = useCallback(async () => {
    setLoading(true);
    const { calenderValue, pageSize, currentPage, amount_max, amount_min, depositTypes } = filters;

    let depositIds = '';
    if (depositTypes) {
      if (depositTypes.length > 1) {
        depositIds = depositTypes?.reduce((prev, curr) => {
          return `${prev.name},${curr.name}`;
        });
      } else if (depositTypes.length === 1) {
        depositIds = depositTypes[0].name;
      }
    }
    const created_date_before = formatDate(calenderValue[1], 'yyyy-MM-dd');
    const created_date_after = formatDate(calenderValue[0], 'yyyy-MM-dd');

    const params = {
      created_date_after,
      created_date_before,
      limit: pageSize,
      deposit_type: depositIds,
      offset: pageSize * currentPage,
      amount_max: amount_max > 0 ? amount_max : '',
      amount_min: amount_min > 0 ? amount_min : '',
    };
    try {
      const response = await merchantApi.get(API.DEPOSIT_REPORT, {
        params,
      });
      setTranslist(response.data);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
      toast.error(err?.data?.data?.message || 'Something went wrong');
    }
  }, [filters]);

  const getHighlights = useCallback(async () => {
    setHighlightLoading(true);
    try {
      const { depositTypes, calenderValue, pageSize, currentPage, amount_max, amount_min } = filters;

      let depositIds = '';
      if (depositTypes) {
        if (depositTypes.length > 1) {
          depositIds = depositTypes?.reduce((prev, curr) => {
            return `${prev.name},${curr.name}`;
          });
        } else if (depositTypes.length === 1) {
          depositIds = depositTypes[0].name;
        }
      }
      const created_date_before = formatDate(calenderValue[1], 'yyyy-MM-dd');
      const created_date_after = formatDate(calenderValue[0], 'yyyy-MM-dd');

      const params = {
        created_date_after,
        created_date_before,
        limit: pageSize,
        offset: pageSize * currentPage,
        amount_max: amount_max > 0 ? amount_max : '',
        amount_min: amount_min > 0 ? amount_min : '',
        deposit_type: depositIds,
      };
      const response = await merchantApi.get(`${API.DEPOSIT_REPORT}highlight/`, {
        params,
      });
      console.log(response);
      setHighlights(response.data.data);
      setHighlightLoading(false);
    } catch (error) {
      console.log(error);
      toast.error(error?.data?.data?.message || 'Something went wrong');
      setHighlightLoading(false);
    }
  }, [filters]);

  useEffect(() => {
    getReport();
    getHighlights();
  }, [
    filters.pageSize,
    filters.currentPage,
    filters.sortModel,
    // getReport,
    // getHighlights,
  ]);

  const resetFilters = () => {
    dispatch({ type: CLEAR_DEPOSIT_REPORT });
    // setRemittanceReport({
    //   depositTypes: [],
    //   calenderValue: [new Date(reportTimePeriods[1].from), null],
    //   period: reportTimePeriods[1],
    //   currentPage: 0,
    //   pageSize: 7,
    //   amount_max: 0,
    //   amount_min: 0,
    // });
  };

  const download = (type) => {
    if (type === 'excel' && translist?.file) {
      window.open(translist?.file, '_blank');
    }
    if (type === 'pdf' && translist?.doc) {
      window.open(translist?.doc, '_blank');
    }
  };
  return (
    <>
      <Box sx={{ padding: '0 24px 24px' }}>
        <Card sx={{ width: '100%' }}>
          <Stack direction='row' divider={<Divider orientation='vertical' flexItem sx={{ borderStyle: 'dashed' }} />} sx={{ py: 2 }}>
            {highlightLoading
              ? [...Array(1)].map((a) => <HighlightSkeleton />)
              : highlights.map((highlight, i) => {
                  return (
                    <Highlight
                      key={highlight.heading}
                      title={highlight.heading}
                      subTitle={highlight.sub_heading}
                      // total={translist.count}
                      percent={100}
                      value={highlight.data}
                      icon={highlight?.meta?.icon}
                      color={highlight?.meta?.color}
                    />
                  );
                })}
          </Stack>
        </Card>

        <Stack sx={{ width: '100%', gap: 1, mb: 2 }}>
          <Stack
            sx={{
              overflowX: { xs: 'auto', },
              overflowY: { xs: 'visible', md: 'unset' },
              padding: '5px 0',
            }}
            direction={'row'}
            justifyContent={'space-between'}
            gap={1}
            mt={2}
            width={'100%'}
          >
            <Stack sx={{ flexDirection: 'row', gap: 1 }} divider={<Divider orientation='vertical' flexItem sx={{ borderStyle: 'dashed' }} />}>
              <Stack sx={{ maxWidth: '150px', flex: 1 }}>
                <FilterDropdown
                  name='timeperiod'
                  type='text'
                  label='Timeperiod'
                  options={reportTimePeriods}
                  onChange={(e) => {
                    const name = e.target.value;
                    const period = reportTimePeriods.find((report) => report.name === name);

                    let toValue;
                    if (filters.calenderValue[1]) {
                      toValue = new Date(period.from) > new Date(filters.calenderValue[1]) ? null : filters.calenderValue[1];
                    } else {
                      toValue = null;
                    }
                    dispatch({
                      type: UPDATE_DEPOSIT_REPORT,
                      payload: {
                        calenderValue: [new Date(period.from), toValue],
                        period,
                      },
                    });
                    // setRemittanceReport((prevState) => ({
                    //   ...prevState,
                    //   calenderValue: [new Date(period.from), toValue],
                    //   period,
                    // }));
                  }}
                  value={filters?.period?.name}
                  size={'small'}
                  render={(period, i) => (
                    <MenuItem key={period.name} value={period.name}>
                      {period.name}
                    </MenuItem>
                  )}
                />
              </Stack>
              <Stack sx={{ flexDirection: 'row', gap: 1 }}>
                <Stack sx={{ width: '176px', flex: 1 }}>
                  <StyledDatePicker
                    value={filters.calenderValue[0]}
                    disableFuture
                    format={'dd/MM/yyyy'}
                    onChange={(newValue) => {
                      dispatch({
                        type: UPDATE_DEPOSIT_REPORT,
                        payload: {
                          calenderValue: [
                            new Date(Format(newValue, 'yyyy/MM/dd')),
                            newValue > filters.calenderValue[1] ? undefined : filters.calenderValue[1],
                          ],
                        },
                      });
                      // setRemittanceReport((prevState) => ({
                      //   ...prevState,
                      //   calenderValue: [
                      //     new Date(Format(newValue, 'yyyy/MM/dd')),
                      //     newValue > filters.calenderValue[1]
                      //       ? undefined
                      //       : filters.calenderValue[1],
                      //   ],
                      // }));
                    }}
                  />
                </Stack>

                <Stack sx={{ width: '176px', flex: 1 }}>
                  <StyledDatePicker
                    value={filters.calenderValue[1]}
                    disableFuture
                    // minDate={filters.calenderValue[0] ?? undefined}
                    format={'dd/MM/yyyy'}
                    onChange={(newValue) => {
                      dispatch({
                        type: UPDATE_DEPOSIT_REPORT,
                        payload: {
                          calenderValue: [
                            newValue < filters.calenderValue[0] ? undefined : filters.calenderValue[0],
                            new Date(Format(newValue, 'yyyy/MM/dd')),
                          ],
                        },
                      });
                      // setRemittanceReport((prevState) => ({
                      //   ...prevState,
                      //   calenderValue: [
                      //     newValue < filters.calenderValue[0]
                      //       ? undefined
                      //       : filters.calenderValue[0],
                      //     new Date(Format(newValue, 'yyyy/MM/dd')),
                      //   ],
                      // }));
                    }}
                  />
                </Stack>
              </Stack>
              <Stack sx={{ flexDirection: 'row', gap: 1 }}>
                <Stack sx={{ width: '176px', flex: 1 }}>
                  <OutlinedInput
                    id='amount-from'
                    size='small'
                    value={filters.amount_min}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value < 0) {
                        dispatch({
                          type: UPDATE_DEPOSIT_REPORT,
                          payload: {
                            amount_min: Math.abs(value),
                          },
                        });
                        // setRemittanceReport((prev) => ({
                        //   ...prev,
                        //   amount_min: Math.abs(value),
                        // }));
                      } else {
                        dispatch({
                          type: UPDATE_DEPOSIT_REPORT,
                          payload: {
                            amount_min: value,
                          },
                        });
                        // setRemittanceReport((prev) => ({
                        //   ...prev,
                        //   amount_min: value,
                        // }));
                      }
                    }}
                    endAdornment={<InputAdornment position='end'>{code}</InputAdornment>}
                    type={'number'}
                    aria-describedby='amount-from'
                    placeholder={'From'}
                    inputProps={{
                      'aria-label': 'amount-from',
                    }}
                  />
                </Stack>

                <Stack sx={{ width: '176px', flex: 1 }}>
                  <OutlinedInput
                    id='amount-to'
                    size='small'
                    value={filters.amount_max}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value < 0) {
                        dispatch({
                          type: UPDATE_DEPOSIT_REPORT,
                          payload: {
                            amount_max: Math.abs(value),
                          },
                        });
                        // setRemittanceReport((prev) => ({
                        //   ...prev,
                        //   amount_max: Math.abs(value),
                        // }));
                      } else {
                        dispatch({
                          type: UPDATE_DEPOSIT_REPORT,
                          payload: {
                            amount_max: value,
                          },
                        });
                        // setRemittanceReport((prev) => ({
                        //   ...prev,
                        //   amount_max: value,
                        // }));
                      }
                    }}
                    endAdornment={<InputAdornment position='end'>{code}</InputAdornment>}
                    type={'number'}
                    aria-describedby='amount-to'
                    placeholder={'To'}
                    inputProps={{
                      'aria-label': 'amount-to',
                    }}
                  />
                </Stack>
              </Stack>
            </Stack>
            <Stack sx={{ flexDirection: 'row', gap: 1 }}>
              <ButtonResetFilter onClick={resetFilters} />

              <ButtonSecondary onClick={() => download('excel')}>
                <Iconify icon={'vscode-icons:file-type-excel'} sx={{ width: '20px' }} />
              </ButtonSecondary>

              <ButtonPrimary
                onClick={() => {
                  getReport();
                  getHighlights();
                }}
                loading={loading}
              >
                Fetch Report
              </ButtonPrimary>
            </Stack>
          </Stack>
          <Accordion sx={{ width: '100%' }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel1a-content' id='panel1a-header'>
              <Typography sx={{ width: '33%', flexShrink: 0 }}>More Filters</Typography>
              <Typography sx={{ color: 'text.secondary' }}>More filters to view specific data analytics</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Stack sx={{ flexDirection: 'row', gap: 1 }}>
                <Stack sx={{ minWidth: '20%', maxWidth: '50%' }}>
                  <Autocomplete
                    multiple={true}
                    limitTags={2}
                    id='deposit_types'
                    value={filters.depositTypes}
                    onChange={(e, newValue) => {
                      console.log(newValue);
                      dispatch({
                        type: UPDATE_DEPOSIT_REPORT,
                        payload: {
                          depositTypes: newValue ?? null,
                        },
                      });
                      // setRemittanceReport((prevState) => ({
                      //   ...prevState,
                      //   depositTypes: newValue ?? null,
                      // }));
                    }}
                    options={depositTypes}
                    getOptionLabel={(option) => option?.name}
                    sx={{ flexGrow: 1, width: '100%' }}
                    renderInput={(params) => {
                      return <TextField placeholder='Select Deposit Types' {...params} size='small' />;
                    }}
                  />
                </Stack>
              </Stack>
            </AccordionDetails>
          </Accordion>
        </Stack>
        <DepositReportTable translist={translist} fetchTranslistLoading={loading} />
      </Box>
    </>
  );
}

export default DepositReport;
