import React, { useState } from 'react';
import 'rsuite/dist/styles/rsuite-default.css';
import { FilterDropdown } from '../components';
import { Box, Stack, MenuItem } from '@mui/material';
import { downloadFormats, reportTimePeriods } from '../common';
import { ButtonPrimary } from '../../../components';
import { DateRangePicker } from 'rsuite';
import usePageTitle from '../../../hooks/usePageTitle';

const { afterToday } = DateRangePicker;

function MtoLedgerSummary() {
  const [mtoLedgerSummary, setMtoLedgerSummary] = useState({
    format: 'pdf',
    calenderValue: [new Date(reportTimePeriods[0].from), new Date(reportTimePeriods[0].to)],
    period: reportTimePeriods[0],
  });

  usePageTitle('MTO Ledger Summary', 'View what we owe and what all MTOs owes to us');

  return (
    <>
      <Box sx={{ padding: '0 24px 24px' }}>
        <Stack direction={'row'} justifyContent={'space-between'} width={'100%'} mb={2}>
          <Stack gap={2} direction={'row'}>
            <Stack sx={{ maxWidth: '35%', flex: 1 }}>
              <FilterDropdown
                name='from'
                type='text'
                label='From'
                options={reportTimePeriods}
                onChange={(e) => {
                  const name = e.target.value;
                  const period = reportTimePeriods.find((report) => report.name === name);
                  console.log(period);
                  setMtoLedgerSummary((prevState) => ({
                    ...prevState,
                    calenderValue: [new Date(period.from), new Date(period.to)],
                    period,
                  }));
                }}
                value={mtoLedgerSummary?.period?.name}
                size={'small'}
                render={(period, i) => (
                  <MenuItem key={period.name} value={period.name}>
                    {period.name}
                  </MenuItem>
                )}
              />
            </Stack>
            <Stack sx={{ maxWidth: '55%', flex: 1 }}>
              <DateRangePicker
                cleanable
                placeholder='Filter Date'
                className='w-100 dateInput'
                size='lg'
                style={{ height: '40px' }}
                // onOk={handleDateFilter}
                value={mtoLedgerSummary.calenderValue}
                placement='auto'
                showOneCalendar={true}
                disabledDate={afterToday()}
                onClean={(dara) => {
                  setMtoLedgerSummary((prevState) => ({
                    ...prevState,
                    calenderValue: [new Date(reportTimePeriods[0].from), new Date(reportTimePeriods[0].to)],
                    period: {
                      from: reportTimePeriods[0].from,
                      to: reportTimePeriods[0].to,
                      name: reportTimePeriods[0].name,
                    },
                  }));
                }}
              />
            </Stack>
            <Stack sx={{ maxWidth: '25%', flex: 0.5 }}>
              <FilterDropdown
                name='from'
                type='text'
                label='From'
                options={downloadFormats}
                onChange={(e) => {
                  const value = e.target.value;
                  setMtoLedgerSummary((prevState) => ({
                    ...prevState,
                    format: value,
                  }));
                }}
                value={mtoLedgerSummary?.format}
                size={'small'}
                render={(format, i) => (
                  <MenuItem key={format.name} value={format.value}>
                    {format.name}
                  </MenuItem>
                )}
              />
            </Stack>
          </Stack>
          <ButtonPrimary>Download</ButtonPrimary>
        </Stack>
      </Box>
    </>
  );
}

export default MtoLedgerSummary;
