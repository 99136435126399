import React from 'react';
import { format } from 'date-fns';
import { startCase, toLower } from 'lodash';
import { Box, Stack, Typography, LinearProgress, Avatar, Tooltip } from '@mui/material';
import { formatWithoutTimezone, rowsPerPageOptions, transactionStatusColors } from '../../../../utils/common';

import { DataGrid } from '@mui/x-data-grid';
import { formatCurrency, tableCellStyle } from '../../../../utils/common';
import createAvatar from '../../../../utils/createAvatar';
import { Label } from '../../../../components';
import { useDispatch, useSelector } from 'react-redux';
import { CLEAR_REDEMPTION_REPORT } from '../../../../redux/actionTypes';

export default function RedemptionTable({ translist, fetchTranslistLoading }) {
  const filters = useSelector((state) => state.filters.reports.redemptionReport);
  const dispatch = useDispatch();
  const handleChangePage = (newPage) => {
    dispatch({
      type: CLEAR_REDEMPTION_REPORT,
      payload: {
        currentPage: newPage,
      },
    });
    // setPaginateTable({ ...filters, currentPage: newPage });
  };

  const handleChangeRowsPerPage = (newSize) => {
    dispatch({
      type: CLEAR_REDEMPTION_REPORT,
      payload: {
        currentPage: 0,
        pageSize: newSize,
      },
    });
    // setPaginateTable({
    //   ...filters,
    //   currentPage: 0,
    //   pageSize: newSize,
    // });
  };

  const handleSortModelChange = (sort) => {
    console.log(sort);
    dispatch({
      type: CLEAR_REDEMPTION_REPORT,
      payload: {
        sortModel: sort,
      },
    });
    // setPaginateTable((prevState) => ({ ...prevState, sortModel: sort }));
  };

  const createDataRow = (transaction) => {
    return {
      id: transaction.ref_number,
      // type: capital(transaction?.type) || 'P2P',
      created_date: formatWithoutTimezone(transaction.created_date, 'dd MMM yyyy HH:mm a'),
      completion_date: formatWithoutTimezone(transaction.modified_date, 'dd MMM yyyy HH:mm a'),

      requested_by: (() => {
        return startCase(toLower(transaction.requester_mto));
      })(),

      redeemable_by: (() => {
        return startCase(toLower(transaction.sender_mto));
      })(),

      redemption_amount: (() => {
        return formatCurrency(
          Number(transaction?.amount),
          transaction?.coin?.decimal_place,
          transaction?.coin?.symbol,
          transaction?.coin?.symbol_prefix
        );
      })(),

      status: transaction?.status,
      remarks: transaction?.remarks,
      // details: transaction,
    };
  };

  const rows = translist?.results?.length > 0 ? translist.results.map((transaction) => createDataRow(transaction)) : [];

  const columns = [
    {
      field: 'id',
      headerName: 'Reference Number',
      flex: 1,
      minWidth: 150,
      // maxWidth: 200,
      headerClassName: 'header',
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      renderCell: (params) => {
        return (
          <Tooltip placement='bottom' sx={{ cursor: 'default' }} title={params.formattedValue}>
            <Typography variant='body2' style={tableCellStyle}>
              {params.formattedValue}
            </Typography>
          </Tooltip>
        );
      },
    },

    {
      field: 'created_date',
      headerName: 'Submission Date',
      flex: 1,
      minWidth: 175,
      // maxWidth: 200,
      headerClassName: 'header',
      renderCell: (params) => {
        return (
          <Typography variant='body2' style={tableCellStyle}>
            {params.formattedValue}
          </Typography>
        );
      },
    },

    {
      field: 'completion_date',
      headerName: 'Completion Date',
      flex: 1.5,
      minWidth: 200,
      // maxWidth: 200,
      headerClassName: 'header',
      sortable: false,
      renderCell: (params) => {
        return (
          <Typography variant='body2' style={tableCellStyle}>
            {params.formattedValue}
          </Typography>
        );
      },
    },

    {
      field: 'requested_by',
      headerName: 'Requested By',
      flex: 1.5,
      minWidth: 200,
      // maxWidth: 200,
      textOverflow: 'ellipsis',
      headerClassName: 'header',
      sortable: false,
      renderCell: (params) => {
        return (
          <Stack direction={'row'} sx={{ minWidth: 0, alignItems: 'center' }}>
            <Avatar alt={params.formattedValue} sx={{ mr: 1, bgcolor: createAvatar(params.formattedValue).color }}>
              {createAvatar(params.formattedValue).name}
            </Avatar>
            <Typography
              variant='body2'
              sx={{
                color: 'black',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                fontWeight: '500',
              }}
            >
              {params.formattedValue}
            </Typography>
          </Stack>
        );
      },
    },

    {
      field: 'redeemable_by',
      headerName: 'Redeemable By',
      flex: 1.5,
      minWidth: 200,
      // maxWidth: 200,
      headerClassName: 'header',
      sortable: false,
      renderCell: (params) => {
        return (
          <Stack direction={'row'} sx={{ minWidth: 0, alignItems: 'center' }}>
            <Avatar alt={params.formattedValue} sx={{ mr: 1, bgcolor: createAvatar(params.formattedValue).color }}>
              {createAvatar(params.formattedValue).name}
            </Avatar>
            <Typography
              variant='body2'
              sx={{
                color: 'black',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                fontWeight: '500',
              }}
            >
              {params.formattedValue}
            </Typography>
          </Stack>
        );
      },
    },

    {
      field: 'redemption_amount',
      headerName: 'Settlement Amount',
      flex: 1.5,
      minWidth: 200,
      // maxWidth: 200,
      headerClassName: 'header',
      sortable: false,
      renderCell: (params) => {
        return (
          <Typography variant='body2' style={tableCellStyle}>
            {params.formattedValue}
          </Typography>
        );
      },
    },

    {
      field: 'status',
      headerName: 'Status',
      flex: 1.5,
      minWidth: 250,
      headerClassName: 'header',
      sortable: false,
      renderCell: (params) => {
        let { value, actionable_name } = params.formattedValue;
        switch (value) {
          case 'pending':
            return (
              <Label variant={'ghost'} color={transactionStatusColors[value]} sx={{ textTransform: 'capitalize' }}>
                {actionable_name}
              </Label>
            );
          case 'processed':
            return (
              <Label variant={'ghost'} color={transactionStatusColors[value]} sx={{ textTransform: 'capitalize' }}>
                {actionable_name}
              </Label>
            );

          case 'reject_request':
            return (
              <Label variant={'ghost'} color={transactionStatusColors[value]} sx={{ textTransform: 'capitalize' }}>
                {actionable_name}
              </Label>
            );
          case 'expired':
            return (
              <Label variant={'ghost'} color={transactionStatusColors[value]} sx={{ textTransform: 'capitalize' }}>
                {actionable_name}
              </Label>
            );
          case 'failed':
            return (
              <Label variant={'ghost'} color={transactionStatusColors[value]} sx={{ textTransform: 'capitalize' }}>
                {actionable_name}
              </Label>
            );
          case 'payout_done':
            return (
              <Label variant={'ghost'} color={transactionStatusColors[value]} sx={{ textTransform: 'capitalize' }}>
                {actionable_name}
              </Label>
            );

          case 'redeem_complete':
            return (
              <Label variant={'ghost'} color={transactionStatusColors[value]} sx={{ textTransform: 'capitalize' }}>
                {actionable_name}
              </Label>
            );

          case 'reject_complete':
            return (
              <Label variant={'ghost'} color={transactionStatusColors[value]} sx={{ textTransform: 'capitalize' }}>
                {actionable_name}
              </Label>
            );
          default:
            return (
              <Label variant={'ghost'} color={'application submitted'} sx={{ textTransform: 'capitalize' }}>
                {actionable_name || '--'}
              </Label>
            );
        }
      },
    },
  ];

  return (
    <>
      <Box
        sx={{
          // height: 825,
          boxShadow: 'rgba(99, 99, 99, 0.2) 0px 0px 8px 0px',
          borderRadius: '8px',
          width: '100%',
          '& .MuiDataGrid-virtualScrollerRenderZone': {
            '& .MuiDataGrid-row': {
              // backgroundColor: 'rgba(235, 235, 235, .7)',
              fontWeight: 500,
              color: '#667085',
              fontFamily: 'Inter, sans-serif',
              fontSize: '14px',
            },
          },
          '& .header': {
            backgroundColor: '#F4F4F4',
            '& div': {
              '& div': {
                '& div': {
                  '& div': {
                    fontSize: '14px',
                    fontWeight: 600,
                    fontFamily: 'Inter, sans-serif',
                  },
                },
              },
            },
          },
        }}
      >
        <DataGrid
          autoHeight
          sx={{
            borderRadius: '8px',
            boxShadow: 'rgba(99, 99, 99, 0.2) 0px 0px 8px 0px',
            width: '100%',
          }}
          components={{
            LoadingOverlay: LinearProgress,
          }}
          // density={'compact'}
          rows={rows}
          columns={columns}
          rowHeight={50}
          disableColumnFilter={true}
          disableColumnMenu={true}
          pagination
          paginationMode='server'
          rowsPerPageOptions={rowsPerPageOptions}
          page={filters.currentPage}
          rowCount={translist.count}
          pageSize={filters.pageSize}
          onPageChange={(newPage) => handleChangePage(newPage)}
          onPageSizeChange={(newSize) => handleChangeRowsPerPage(newSize)}
          loading={fetchTranslistLoading}
          sortingMode='server'
          sortModel={filters.sortModel}
          onSortModelChange={handleSortModelChange}
        />
      </Box>
    </>
  );
}
