import React, { useState } from "react";
import { styled } from "@material-ui/core/styles";
import { Typography, Stack, Box, Paper } from "@mui/material";
import Image from "../../../../components/image/Image";
import { AddNewCard } from "../../../../components/addNewCard";
import { UploadDocument } from "../../../../components/uploadDocumentModal";
import { DocumentCard } from "../../../../components/documentCard";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { merchantApi } from "../../../../redux/api/apiConfig";
import { REMITTANCE_OUTGOING, SENDER_DOCUMENT } from "../../../../ApiUrl";
import { useCallback } from "react";
import { ButtonPrimary } from "../../../../components";
import StatusModal from "../../inward/components/statusModal/StatusModal";
import { useHistory } from "react-router-dom";
import { Routes } from "../../../../routes";
import { useSelector } from "react-redux";

const MyPaper = styled(Paper)({
  borderRadius: 8,
  borderColor: "#000",
  backgroundColor: "#F0F0F0",
  padding: "1rem",
});
const DocumentDetails = ({ transaction, getTransasction, isInward }) => {
  const [showDocumentUpload, setShowDocumentUpload] = useState(false);
  const [selectedDocuments, setSelectedDocuments] = useState(
    transaction.sender_document
  );
  const [updateLoading, setUpdateLoading] = useState(false);
  const [showStatusModal, setShowStatusModal] = useState(false);

  const history = useHistory();

  const {
    current_status: { can_update_document = false },
    sender,
  } = transaction;

  const { profileReducer: { user: { is_mediator: isMediator } = {} } = {} } =
    useSelector((state) => state);

  const toggleDocumentUpload = () => {
    setShowDocumentUpload((prevState) => !prevState);
  };

  const toggleStatusModal = () => {
    setShowStatusModal((prevState) => !prevState);
  };

  const onDocumentSelect = (res) => {
    let newDocArray = [...selectedDocuments];
    newDocArray.push(res);
    console.log(newDocArray);
    setSelectedDocuments(newDocArray);
  };
  const onDocumentDelete = async (id) => {
    try {
      let newDocArray = selectedDocuments;
      newDocArray = newDocArray.filter((doc) => Number(doc.id) !== Number(id));
      setSelectedDocuments(newDocArray);
    } catch (error) {
      console.log(error);
    }
  };

  const updateClickHandler = async (status, remarks) => {
    try {
      setUpdateLoading(true);
      const response = await merchantApi.patch(
        `${REMITTANCE_OUTGOING}${transaction.id}/`,
        {
          sender_document: selectedDocuments.reduce((acc, curr) => {
            acc.push(Number(curr.id));
            return acc;
          }, []),
          status,
          remarks,
          // status:
        }
      );
      console.log(response);
      setUpdateLoading(false);
      getTransasction();
      toggleStatusModal();
      history.push(Routes.OutwardRemittance.path);
      // getCounts();
    } catch (error) {
      setUpdateLoading(false);
      toast.error(error?.data?.data?.message || "Something went wrong");
    }
  };

  return (
    <>
      <Box sx={{ marginTop: "1.5rem" }}>
        <MyPaper sx={{ flex: "1" }} elevation={0}>
          <Stack direction={"column"} spacing={2} sx={{ flex: 1 }}>
            <Typography variant={"h6"}>Documents</Typography>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "repeat(auto-fit, minmax(200px, 200px))",
                gridGap: "1rem",
                width: "100%",
              }}
            >
              {isInward || isMediator ? (
                selectedDocuments?.map((document, i) => {
                  return (
                    // <a href={document.document} target='_blank' rel='noopener noreferrer'>
                    //   <Image
                    //     key={document.id}
                    //     alt='file preview'
                    //     src={document.document}
                    //     title={`${document?.name ?? ''} (${document?.document_type?.name ?? ''})`}
                    //     sx={{
                    //       borderRadius: 1,
                    //       width: '200px',
                    //       height: '150px',
                    //       cursor: 'pointer',
                    //     }}
                    //   />
                    // </a>
                    <DocumentCard documents={document} />
                  );
                })
              ) : (
                <>
                  {can_update_document
                    ? selectedDocuments?.map((document, i) => {
                        return (
                          <DocumentCard
                            documents={document}
                            onDocumentDelete={onDocumentDelete}
                            canDelete={true}
                          />
                        );
                      })
                    : selectedDocuments?.map((document, i) => {
                        return (
                          // <a
                          //   href={document.document}
                          //   target="_blank"
                          //   rel="noopener noreferrer"
                          // >
                          //   <Image
                          //     key={document.id}
                          //     alt="file preview"
                          //     src={document.document}
                          //     title={`${document?.name ?? ""} (${
                          //       document?.document_type?.name ?? ""
                          //     })`}
                          //     sx={{
                          //       borderRadius: 1,
                          //       width: "200px",
                          //       height: "150px",
                          //       cursor: "pointer",
                          //     }}
                          //   />
                          // </a>
                          <DocumentCard documents={document} />
                        );
                      })}
                  {can_update_document && !isInward && !isMediator && (
                    <AddNewCard onClick={toggleDocumentUpload} />
                  )}
                </>
              )}
            </Box>
          </Stack>

          {can_update_document && !isInward && !isMediator ? (
            <Stack
              sx={{
                width: "fit-content",
                marginLeft: "auto",
                marginTop: "1rem",
              }}
            >
              <ButtonPrimary
                onClick={toggleStatusModal}
                disabled={selectedDocuments.length === 0}
              >
                Update
              </ButtonPrimary>
            </Stack>
          ) : (
            ""
          )}
        </MyPaper>
      </Box>
      {showDocumentUpload && (
        <UploadDocument
          isOpen={showDocumentUpload}
          onClose={toggleDocumentUpload}
          senderId={sender.id}
          updateDocs={onDocumentSelect}
        />
      )}
      {showStatusModal && (
        <StatusModal
          isOpen={showStatusModal}
          onClose={toggleStatusModal}
          updateClickHandler={updateClickHandler}
          mtoPatchLoading={updateLoading}
          status={transaction?.current_status}
          canChangeTo={
            isMediator
              ? transaction?.current_status?.mediator_can_change_to
              : transaction?.current_status?.sender_can_change_to
          }
        />
      )}
    </>
  );
};

export default DocumentDetails;
