import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Box, Typography, LinearProgress } from '@mui/material';
import back from '../../../../../assets/img/icons/back.svg';
import { DataGrid } from '@mui/x-data-grid';
import { rowsPerPageOptions, tableCellStyle } from '../../../../../utils/common';
import { merchantApi } from '../../../../../redux/api/apiConfig';
import { MTO_BALANCES } from '../../../../../ApiUrl';
import { formatCurrency } from '../../../../../utils/common';
import { ButtonGhost } from '../../../../../components';
import { Routes } from '../../../../../routes';
import usePageTitle from '../../../../../hooks/usePageTitle';
import { toast } from 'react-toastify';

export default function AccountBalance() {
  const [fetchBalanceLoading, setFetchBalanceLoading] = useState(false);
  const [balanceList, setBalanceList] = useState({});
  const [paginateTable, setpaginateTable] = useState({
    currentPage: 0,
    pageSize: 7,
    search: '',
    type: '',
    coin: '',
    store: '',
    status: '',
    fromDate: '',
    toDate: '',
  });

  const params = useParams();
  const history = useHistory();

  usePageTitle('Account Balances', balanceList?.results?.[0]?.mto_account?.name);

  const getTrans = useCallback(async () => {
    setFetchBalanceLoading(true);
    try {
      const mto_account = params.id;
      const response = await merchantApi.get(`${MTO_BALANCES}`, {
        params: {
          mto_account,
          offset: paginateTable.currentPage * paginateTable.pageSize,
          limit: paginateTable.pageSize,
          // search: paginateTable.search,
          // type: paginateTable.type,
          // coin: paginateTable.coin,
          // store: paginateTable.store,
          // status: paginateTable.status,
          // modified_date_after: paginateTable.fromDate,
          // modified_date_before: paginateTable.toDate,
        },
      });

      setFetchBalanceLoading(false);
      console.log(response);
      if (response.data) {
        setBalanceList(response.data);
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.data?.data?.message || 'Something went wrong');
      setFetchBalanceLoading(false);
    }
  }, [paginateTable]);

  useEffect(() => {
    getTrans();
  }, [
    paginateTable.currentPage,
    paginateTable.pageSize,
    paginateTable.search,
    paginateTable.type,
    paginateTable.coin,
    paginateTable.store,
    paginateTable.status,
    paginateTable.fromDate,
    paginateTable.toDate,
    // resetData,
    getTrans,
  ]);

  useEffect(() => {
    console.log(params.id);
  }, []);

  const handleChangePage = (newPage) => {
    setpaginateTable({ ...paginateTable, currentPage: newPage });
  };

  const handleChangeRowsPerPage = (newSize) => {
    setpaginateTable({
      ...paginateTable,
      currentPage: 0,
      pageSize: newSize,
    });
  };

  const createDataRow = (currency) => {
    console.log(currency);
    return {
      id: currency?.coin?.id,
      currency: currency?.coin?.symbol,
      balance: formatCurrency(currency?.balance, currency?.coin?.decimal_place, currency?.coin?.symbol),
      accountId: params.id,
    };
  };

  const viewTransactions = (coinId, accountId, symbol) => {
    history.push({
      pathname: Routes.ReportTransactions.path,
      state: { account: accountId, coin: coinId, symbol },
    });
  };

  const rows = balanceList?.results?.length > 0 ? balanceList.results.map((transaction) => createDataRow(transaction)) : [];

  const columns = [
    {
      field: 'currency',
      headerName: 'Currency',
      flex: 1,
      minWidth: 150,
      // maxWidth: 200,
      headerClassName: 'header',
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        return (
          <Typography variant='body2' style={tableCellStyle}>
            {params.formattedValue}
          </Typography>
        );
      },
    },

    {
      field: 'balance',
      headerName: 'Balance',
      flex: 1,
      minWidth: 150,
      // maxWidth: 200,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header',
      renderCell: (params) => {
        return (
          <Typography variant='body2' style={tableCellStyle}>
            {params.formattedValue}
          </Typography>
        );
      },
    },
    {
      field: 'accountId',
      headerName: 'View Transactions',
      sortable: false,
      flex: 1.5,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header',
      minWidth: 160,
      renderCell: (params) => {
        return (
          <ButtonGhost
            appearance='ghost'
            size='xs'
            onClick={() => {
              console.log(params.row.id, params.row.accountId, params);
              viewTransactions(params.row.id, params.row.accountId, params.row.currency);
            }}
          >
            View Transactions
          </ButtonGhost>
        );
      },
    },
  ];
  const backClickHandler = () => {
    history.push(Routes.Accounts.path);
  };

  return (
    <Box sx={{ padding: '24px ' }}>
      <span onClick={backClickHandler} className='back mb-4'>
        <img src={back} alt='back' />
        <span>Back</span>
      </span>
      {/* <div className="d-flex flex-col align-items-start gap-4">
        <div className="d-flex space-between w-100">
          <div>
            <h3 className="m-0 page-title">Account Balances</h3>
            <p>View your account balances</p>
          </div>
        </div>
      </div> */}
      <Box
        sx={{
          boxShadow: 'rgba(99, 99, 99, 0.2) 0px 0px 8px 0px',
          borderRadius: '8px',
          marginTop: 5,
          width: '100%',
          '& .MuiDataGrid-virtualScrollerRenderZone': {
            '& .MuiDataGrid-row': {
              fontWeight: 500,
              color: '#667085',
              fontFamily: 'Inter, sans-serif',
              fontSize: '14px',
            },
          },
          '& .header': {
            backgroundColor: '#F4F4F4',
            '& div': {
              '& div': {
                '& div': {
                  '& div': {
                    fontSize: '14px',
                    fontWeight: 600,
                    fontFamily: 'Inter, sans-serif',
                  },
                },
              },
            },
          },
        }}
      >
        <DataGrid
          autoHeight
          sx={{
            borderRadius: '8px',
            boxShadow: 'rgba(99, 99, 99, 0.2) 0px 0px 8px 0px',
          }}
          components={{
            LoadingOverlay: LinearProgress,
          }}
          rows={rows}
          columns={columns}
          rowHeight={50}
          disableColumnFilter={true}
          disableColumnMenu={true}
          pagination
          paginationMode='server'
          rowsPerPageOptions={rowsPerPageOptions}
          page={paginateTable.currentPage}
          rowCount={balanceList.count}
          pageSize={paginateTable.pageSize}
          onPageChange={(newPage) => handleChangePage(newPage)}
          onPageSizeChange={(newSize) => handleChangeRowsPerPage(newSize)}
          loading={fetchBalanceLoading}
        />
      </Box>
    </Box>
  );
}
