import React, { useMemo, useState } from 'react';
import { startCase } from 'lodash';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import { DataGrid } from '@mui/x-data-grid';
import { Label } from '../../../../components';
import useDeleteModal from '../../../../hooks/useDeleteModal';
import { DeleteModal } from '../../../../components/deleteModal';
import { rowsPerPageOptions, tableCellStyle, tableStyle } from '../../../../utils/common';
import HistoryIcon from '@mui/icons-material/History';
import { Box, Stack, Typography, LinearProgress, Tooltip, Avatar } from '@mui/material';

import createAvatar from '../../../../utils/createAvatar';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';

const UserKycTable = ({
  users,
  paginateTable,
  setPaginateTable,
  fetchUsersLoading,
  setClientToEdit,
  setIsEdit,
  toggleAddUserModal,
  deleteUser,
  setSelectedKycId,
  toggleUpdateKycModal,
}) => {
  const [deleteId, setDeleteId] = useState('');

  const role = useSelector((state) => state.profileReducer?.user?.role?.key);
  const permission = useSelector((state) => state.profileReducer.permission);

  const { showDeleteModal, toggleShowDeleteModal } = useDeleteModal();
  const NoRowsOverlay = () => {
    return (
      <Stack height='100%' alignItems='center' justifyContent='center'>
        {paginateTable.search === '' ? 'No Clients Added' : 'No Clients Found'}
      </Stack>
    );
  };

  const confirmDelete = () => {
    deleteUser(deleteId);
    toggleShowDeleteModal();
  };

  const createDataRow = (user) => {
    return {
      id: user.id,
      name: startCase(
        user.user.type === 'individual' ? `${user?.user?.data?.first_name} ${user?.user?.data?.last_name}` : user.user.data.business_name
      ),
      kycLevel: startCase(user?.level?.name || '--'),
      volume: user?.transaction_amount ?? '--',
      actions: user,
    };
  };

  const rows = useMemo(() => (users?.results?.length > 0 ? users.results.map((user) => createDataRow(user)) : []), [users.results]);

  const columns = useMemo(
    () => [
      {
        field: 'id',
        headerName: 'ID',
        // flex: 1,
        minWidth: 80,
        // maxWidth: 80,
        headerClassName: 'header',
        align: 'center',
        headerAlign: 'center',
        renderCell: (params) => {
          return (
            <Typography variant='body2' style={tableCellStyle}>
              {params.formattedValue}
            </Typography>
          );
        },
        // align: 'center',
      },
      {
        field: 'name',
        headerName: 'Name',
        sortable: false,
        flex: 1,
        minWidth: 300,
        // maxWidth: 250,
        headerClassName: 'header',
        renderCell: (params) => {
          return (
            <Stack direction={'row'} sx={{ minWidth: 0, alignItems: 'center' }}>
              <Avatar
                alt={params.formattedValue}
                sx={{
                  mr: 2,
                  bgcolor: createAvatar(params.formattedValue).color,
                }}
              >
                {createAvatar(params.formattedValue).name}
              </Avatar>
              <Typography
                variant='body2'
                sx={{
                  color: 'black',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  fontWeight: '500',
                }}
              >
                {params.formattedValue}
              </Typography>
            </Stack>
          );
        },
        // align: 'center',
        // headerAlign: 'center',
      },
      {
        field: 'volume',
        headerName: 'Transaction Volume (30 Days)',
        sortable: false,
        flex: 1,
        minWidth: 250,
        headerClassName: 'header',
        renderCell: (params) => {
          return (
            <Typography variant='body2' sx={tableCellStyle}>
              {params.formattedValue}
            </Typography>
          );
        },
        // align: 'center',
      },

      {
        field: 'kycLevel',
        headerName: 'KYC Level',
        sortable: false,
        flex: 1,
        minWidth: 140,
        headerClassName: 'header',
        renderCell: (params) => {
          return (
            <Label color={'pending'} variant={'ghost'}>
              {params.formattedValue}
            </Label>
            // <Typography variant="body2" sx={tableCellStyle}>
            //   {params.formattedValue}
            // </Typography>
          );
        },
        // align: 'center',
      },
      {
        field: 'actions',
        headerName: 'Actions',
        sortable: false,
        flex: 1,
        minWidth: 150,
        maxWidth: 150,
        headerClassName: 'header',
        renderCell: (params) => {
          console.log(params);
          return (
            <Stack direction='row' spacing={2}>
              {permission?.clients?.senders?.userKyc?.permission?.change[role] && (
                <Stack>
                  <Tooltip title='Update' arrow>
                    <IconButton
                      onClick={() => {
                        setSelectedKycId(params.row.id);
                        toggleUpdateKycModal();
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                </Stack>
              )}
              <Stack>
                <Tooltip title='KYC Change History' arrow>
                  <IconButton>
                    <HistoryIcon />
                  </IconButton>
                </Tooltip>
              </Stack>
            </Stack>
          );
        },
      },
    ],
    []
  );

  const onActivateClicked = (client) => {
    setIsEdit(true);
    setClientToEdit(client);
    toggleAddUserModal();
  };
  const handleChangePage = useCallback((newPage) => {
    setPaginateTable({ ...paginateTable, currentPage: newPage });
  }, []);

  const handleChangeRowsPerPage = useCallback((newSize) => {
    setPaginateTable({
      ...paginateTable,
      currentPage: 0,
      pageSize: newSize,
    });
  }, []);

  return (
    <>
      <Box
        sx={{
          height: 615,
          width: 1,
          '& .MuiDataGrid-virtualScrollerRenderZone': {
            '& .MuiDataGrid-row': {
              // backgroundColor: 'rgba(235, 235, 235, .7)',
              fontWeight: 500,
              color: '#667085',
              fontFamily: 'Inter, sans-serif',
              fontSize: '14px',
            },
          },
          '& .header': {
            backgroundColor: '#F4F4F4',
            '& div': {
              '& div': {
                '& div': {
                  '& div': {
                    fontSize: '14px',
                    fontWeight: 600,
                    fontFamily: 'Inter, sans-serif',
                  },
                },
              },
            },
          },
        }}
      >
        <DataGrid
          autoHeight
          sx={tableStyle}
          rows={rows}
          columns={columns}
          rowHeight={72}
          disableColumnFilter={true}
          disableColumnMenu={true}
          pagination
          paginationMode='server'
          rowsPerPageOptions={rowsPerPageOptions}
          loading={fetchUsersLoading}
          pageSize={paginateTable.pageSize}
          page={paginateTable.currentPage}
          rowCount={users.count}
          onPageChange={handleChangePage}
          onPageSizeChange={handleChangeRowsPerPage}
          components={{ NoRowsOverlay, LoadingOverlay: LinearProgress }}
        />
      </Box>
      {showDeleteModal && <DeleteModal onClose={toggleShowDeleteModal} isOpen={showDeleteModal} onSubmit={confirmDelete} text={'the client'} />}
    </>
  );
};

export default UserKycTable;
