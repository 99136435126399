import * as API from '../../ApiUrl';

import { toast } from 'react-toastify';
import { GET_PROFILE, SET_PROFILE, SET_PERMISSIONS, GET_PERMISSIONS, CLEAR_STORE_DATA } from '../actionTypes';
import { merchantApi } from '../api/apiConfig';

const getProfileData = () => async (dispatch) => {
  dispatch({ type: GET_PROFILE });

  try {
    const res = await merchantApi.get(API.MTO_PROFILE);
    if (!res?.data?.data?.role) {
      toast.error('Your role is not defined, please contact your administrator.');
      let timer = setTimeout(() => {
        localStorage.clear();
        dispatch({ type: CLEAR_STORE_DATA });
        // window.location.href = '/';
      }, 1000);
    } else {
      dispatch({
        type: SET_PROFILE,
        payload: res.data.data,
      });
    }
  } catch (err) {
    console.log(err, 'errr');
    toast.error('Unable to fetch user profile.');
    let timer = setTimeout(() => {
      localStorage.clear();
      dispatch({ type: CLEAR_STORE_DATA });
      // window.location.href = '/';
    }, 1000);
  }
};
const getPermissions = () => async (dispatch) => {
  dispatch({ type: GET_PERMISSIONS, payload: true });

  try {
    const res = await merchantApi.get(API.MTO_PERMISSIONS);
    console.log("PERMISSIONSSSSS",res.data.data);
    dispatch({
      type: SET_PERMISSIONS,
      payload: res.data.data,
    });
    let timer = setTimeout(() => {
      dispatch({ type: GET_PERMISSIONS, payload: false });
    }, 500);
  } catch (err) {
    toast.error('Unable to fetch permissions.');
    let timer = setTimeout(() => {
      localStorage.clear();
      dispatch({ type: CLEAR_STORE_DATA });
      // window.location.href = '/';
    }, 1000);
  }
};

export { getProfileData, getPermissions };
