import axios from "axios";
import * as API from "../../ApiUrl";
import {
  GET_COINS,
  GET_WALLETS,
  RESET_ACTIVE_KEY,
  RESET_LOADING,
  SET_CONVERSATION,
  SET_LOADING,
  SET_TICKETS,
} from "../actionTypes";
import { merchantApi } from "../api/apiConfig";

const getConversation = (id) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING });
    try {
      const response = await merchantApi.get(`${API.SUPPORT_TICKET}${id}/`);
      dispatch({ type: SET_CONVERSATION, payload: response.data.data.id });
    } catch (error) {
      dispatch({ type: RESET_LOADING });
    }
  };
};
const sendMessage = (data, ticketId, isAttachment, offset) => {
  return async (dispatch) => {
    // dispatch({ type: SET_LOADING });
    if (!isAttachment) {
      try {
        const response = await merchantApi.post(API.SUPPORT_TICKET_REPLY, data);

        dispatch(getAllTickets(offset));
      } catch (error) {
        dispatch({ type: RESET_LOADING });
      }
    } else {
      let token = localStorage.getItem("accessToken");
      const config = {
        headers: {
          "Content-Type":
            "multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW",
          Authorization: `Bearer ${token}`,
        },
      };
      try {
        const response = await merchantApi.post(
          API.SUPPORT_TICKET_REPLY,
          data,
          config
        );
        dispatch(getAllTickets());
      } catch (error) {
        // dispatch({ type: RESET_LOADING });
      }
    }
  };
};

// const createTicket = (data) => {
//   return async (dispatch) => {
//     // dispatch(slice.actions.startLoading());
//     try {
//       const response = await merchantApi.post(API.SUPPORT_TICKET, data);
//       console.log(response);
//       dispatch(getAllTickets());
//     } catch (error) {
//       // dispatch(slice.actions.hasError(error));
//       console.log(error);
//       throw new Error(error?.message ?? 'Failed');
//     }
//   };
// };

const getAllTickets = (offset) => {
  return async (dispatch) => {
    // dispatch(slice.actions.startLoading());
    dispatch({ type: SET_LOADING });
    try {
      const response = await merchantApi.get(API.SUPPORT_TICKET, {
        params: {
          limit: offset,
        },
      });
      dispatch({ type: SET_TICKETS, payload: response.data.results });
    } catch (error) {
      dispatch({ type: RESET_LOADING });
      // dispatch(slice.actions.hasError(error));
    }
  };
};

const toggleStatus = (status, id, offset) => {
  return async (dispatch) => {
    // dispatch(slice.actions.startLoading());
    try {
      const response = await merchantApi.patch(`${API.SUPPORT_TICKET}${id}/`, {
        status,
      });

      dispatch(getAllTickets(offset));
    } catch (error) {
      // dispatch(slice.actions.hasError(error));
      throw new Error(error?.message ?? "Failed");
    }
  };
};

const resetActiveConversation = (data) => {
  return async (dispatch) => {
    // dispatch(slice.actions.startLoading());
    try {
      dispatch({ type: SET_CONVERSATION, payload: null });
    } catch (error) {
      // dispatch(slice.actions.hasError(error));
    }
  };
};

export {
  getConversation,
  sendMessage,
  getAllTickets,
  toggleStatus,
  // createTicket,
  resetActiveConversation,
};
