import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Stack, Typography, LinearProgress, Avatar, Tooltip, IconButton } from '@mui/material';
import useDeleteModal from '../../../../../hooks/useDeleteModal';
import { DeleteModal } from '../../../../../components/deleteModal';
import DeleteIcon from '@mui/icons-material/Delete';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import { useSelector } from 'react-redux';
import { DataGrid } from '@mui/x-data-grid';
import { rowsPerPageOptions, tableCellStyle } from '../../../../../utils/common';

import createAvatar from '../../../../../utils/createAvatar';
import { Label } from '../../../../../components';

export default function AccountsTable({ accountList, paginateTable, setpaginateTable, fetchTranslistLoading, deleteAccount }) {
  const history = useHistory();
  const [deleteId, setDeleteId] = useState('');

  const { showDeleteModal, toggleShowDeleteModal } = useDeleteModal();
  const role = useSelector((state) => state.profileReducer?.user?.role?.key);
  const permission = useSelector((state) => state.profileReducer?.permission);

  const confirmDelete = () => {
    deleteAccount(deleteId);
    toggleShowDeleteModal();
  };

  const handleChangePage = (newPage) => {
    setpaginateTable({ ...paginateTable, currentPage: newPage });
  };

  const handleChangeRowsPerPage = (newSize) => {
    setpaginateTable({
      ...paginateTable,
      currentPage: 0,
      pageSize: newSize,
    });
  };

  const createDataRow = (transaction) => ({
    id: transaction.id,
    name: transaction?.name,
    description: transaction?.description,
    type: transaction?.mto_type,
    actions: transaction,
  });

  const rows = accountList?.results?.length > 0 ? accountList.results.map((transaction) => createDataRow(transaction)) : [];

  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      flex: 1,
      minWidth: 60,
      // maxWidth: 200,
      headerClassName: 'header',
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        return (
          <Tooltip sx={{ cursor: 'default' }} title={params.formattedValue}>
            <Typography variant='body2' style={tableCellStyle}>
              {params.formattedValue}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      field: 'name',
      headerName: 'Name',
      flex: 1.5,
      minWidth: 250,
      // maxWidth: 200,
      textOverflow: 'ellipsis',
      headerClassName: 'header',
      renderCell: (params) => {
        return (
          <Stack direction={'row'} sx={{ minWidth: 0, alignItems: 'center' }}>
            <Avatar alt={params.formattedValue} sx={{ mr: 1, bgcolor: createAvatar(params.formattedValue).color }}>
              {createAvatar(params.formattedValue).name}
            </Avatar>
            <Typography
              variant='body2'
              sx={{
                color: 'black',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                fontWeight: '500',
              }}
            >
              {params.formattedValue}
            </Typography>
          </Stack>
        );
      },
    },
    {
      field: 'description',
      headerName: 'Description',
      flex: 10,
      minWidth: 150,
      // maxWidth: 200,
      headerClassName: 'header',
      renderCell: (params) => {
        return (
          <Typography variant='body2' style={tableCellStyle}>
            {params.formattedValue}
          </Typography>
        );
      },
    },
    {
      field: 'type',
      headerName: 'Type',
      // flex: 1,
      minWidth: 60,
      // maxWidth: 150,
      headerClassName: 'header',
      renderCell: (params) => {
        return (
          <Label variant={'ghost'} color={'pending'} sx={{ textTransform: 'capitalize' }}>
            {params.formattedValue}
          </Label>
        );
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      flex: 1,
      minWidth: 200,
      // maxWidth: 140,
      headerClassName: 'header',
      renderCell: (params) => {
        return (
          <Stack direction='row' spacing={2}>
            <Stack>
              <Tooltip title='Delete' arrow>
                <span>
                  <IconButton
                    disabled={!params.formattedValue.user_created}
                    onClick={() => {
                      setDeleteId(params.value.id);
                      toggleShowDeleteModal();
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </Stack>
            {permission.accounts?.account?.permission?.viewBalance[role] && (
              <Stack>
                <Tooltip title='View Balances' arrow>
                  <IconButton
                    onClick={() => {
                      history.push(`/accounts/balance/${params.formattedValue.id}`);
                    }}
                  >
                    <AccountBalanceWalletIcon />
                  </IconButton>
                </Tooltip>
              </Stack>
            )}
          </Stack>
        );
      },
    },
  ];

  return (
    <>
      <Box
        sx={{
          boxShadow: 'rgba(99, 99, 99, 0.2) 0px 0px 8px 0px',
          borderRadius: '8px',
          width: '100%',
          '& .MuiDataGrid-virtualScrollerRenderZone': {
            '& .MuiDataGrid-row': {
              fontWeight: 500,
              color: '#667085',
              fontFamily: 'Inter, sans-serif',
              fontSize: '14px',
            },
          },
          '& .header': {
            backgroundColor: '#F4F4F4',
            '& div': {
              '& div': {
                '& div': {
                  '& div': {
                    fontSize: '14px',
                    fontWeight: 600,
                    fontFamily: 'Inter, sans-serif',
                  },
                },
              },
            },
          },
        }}
      >
        <DataGrid
          autoHeight
          sx={{
            borderRadius: '8px',
            width: '100%',
          }}
          components={{
            LoadingOverlay: LinearProgress,
          }}
          rows={rows}
          columns={columns}
          rowHeight={50}
          disableColumnFilter={true}
          disableColumnMenu={true}
          pagination
          paginationMode='server'
          rowsPerPageOptions={rowsPerPageOptions}
          page={paginateTable.currentPage}
          rowCount={accountList.count}
          pageSize={paginateTable.pageSize}
          onPageChange={(newPage) => handleChangePage(newPage)}
          onPageSizeChange={(newSize) => handleChangeRowsPerPage(newSize)}
          loading={fetchTranslistLoading}
        />
      </Box>
      {showDeleteModal && <DeleteModal onClose={toggleShowDeleteModal} isOpen={showDeleteModal} onSubmit={confirmDelete} text={'the account'} />}
    </>
  );
}
